import { persistStaffProfileReducerToLocalStorage } from '../select-staff.util';
import { SelectStaffActions } from './select-staff.actions';
import type { LabsGqlDoctorPreferencesFragment } from '@orthly/graphql-operations';
import type { LabsGqlStaffRolePractice } from '@orthly/graphql-schema';
import { handleActions } from 'redux-actions';

export enum ResponsibilityOption {
    CreateNewOrders = 'CreateNewOrders',
    ManageNotifications = 'ManageNotifications',
    ManageBilling = 'ManageBilling',
    TrackOrders = 'TrackOrders',
    ChatWithDandy = 'ChatWithDandy',
}

export type StaffProfileInfo = Pick<
    LabsGqlDoctorPreferencesFragment,
    '__typename' | 'id' | 'contact_email' | 'name' | 'roles' | 'staff_member_id'
>;

export type StaffForPicker = {
    id: string;
    contact_email?: string | null;
    name: string;
    staff_member_id: string | null;
    roles: LabsGqlStaffRolePractice[] | null;
    __typename?: string | undefined;
};

export interface SelectStaffState {
    skipped: boolean;
    profile?: StaffForPicker;
}

const initialState: SelectStaffState = {
    skipped: false,
};

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const staffMemberReducerRaw = handleActions<SelectStaffState, any>(
    {
        ...SelectStaffActions.SELECT_PRACTICE_EMPLOYEE.reducer((_state, action) => ({
            ...initialState,
            profile: action.payload,
        })),
        ...SelectStaffActions.SKIP_SELECT_STAFF.reducer(state => ({
            ...state,
            skipped: true,
        })),
        ...SelectStaffActions.LOGOUT_STAFF.reducer(() => initialState),
    },
    initialState,
);

export const staffMemberReducer = persistStaffProfileReducerToLocalStorage(staffMemberReducerRaw);
