import { useChat } from '../useChat';
import { ChatWindowContent } from './ChatWindowContent';
import { useChatWindow } from './useChatWindow';
import { LabsGqlLiveCasePlanningRequestEntryPoint } from '@orthly/graphql-schema';
import { FlossPalette, MiniWindowCore, MiniWindowVariant, MiniWindowVariantCSSClass, stylesFactory } from '@orthly/ui';
import { useFeatureFlag, CasePlanningCalendlyModal, GetSupportWindowContent } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(theme => ({
    window: {
        maxWidth: '100vw',
        maxHeight: '100vh',
        width: 512,
        borderRadius: '0 0 8px 8px',
        border: `1px solid ${FlossPalette.STROKE_LIGHT}`,
        backgroundColor: FlossPalette.TAN,
        overflow: 'scroll',
        [`&.${MiniWindowVariantCSSClass.Restored}`]: {
            height: 512,
            borderRadius: 8,
        },
        [`&.${MiniWindowVariantCSSClass[MiniWindowVariant.Maximized]}`]: {
            boxShadow: 'none',
        },
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
        },
    },
}));

export const ChatWindowBase: React.VFC = () => {
    const classes = useStyles();
    const {
        platform,
        windowVariant,
        windowPendingAnimation,
        setWindowPendingAnimation,
        setWindowVariantWithAnimation,
    } = useChatWindow();
    const { value: enableCasePlanning = false } = useFeatureFlag('enableCasePlanning');
    const [casePlanningOpen, setCasePlanningOpen] = React.useState(false);
    const { openChat } = useChat();
    const entryPoint = LabsGqlLiveCasePlanningRequestEntryPoint.PracticeGetSupport;

    return (
        <>
            <MiniWindowCore
                variant={windowVariant}
                setVariant={platform === 'portal' ? setWindowVariantWithAnimation : () => {}}
                pendingAnimation={windowPendingAnimation}
                setPendingAnimation={setWindowPendingAnimation}
                className={classes.window}
            >
                {enableCasePlanning ? (
                    <GetSupportWindowContent
                        setCasePlanningOpen={setCasePlanningOpen}
                        openChat={openChat}
                        entryPoint={entryPoint}
                    />
                ) : (
                    <ChatWindowContent />
                )}
            </MiniWindowCore>
            <CasePlanningCalendlyModal
                casePlanningOpen={casePlanningOpen}
                setCasePlanningOpen={setCasePlanningOpen}
                entryPoint={entryPoint}
            />
        </>
    );
};
