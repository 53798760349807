import { GetSupportWindowContent } from '../GetSupport';
import { CasePlanningCalendlyModal } from './CasePlanningCalendlyModal';
import { LabsGqlLiveCasePlanningRequestEntryPoint } from '@orthly/graphql-schema';
import React from 'react';

// this is specifically used for the get support iFrame, if reusing update the entry point, and you will
// most likely need to pass additional props to CasePlanningCalendlyModal to have it operate correctly
export const CasePlanningModalWrapper: React.VFC = () => {
    const [casePlanningOpen, setCasePlanningOpen] = React.useState(false);
    const entryPoint = LabsGqlLiveCasePlanningRequestEntryPoint.UploaderGetSupport;

    return (
        <>
            <GetSupportWindowContent setCasePlanningOpen={setCasePlanningOpen} entryPoint={entryPoint} />
            <CasePlanningCalendlyModal
                casePlanningOpen={casePlanningOpen}
                setCasePlanningOpen={setCasePlanningOpen}
                entryPoint={entryPoint}
            />
        </>
    );
};
