import type { CheckoutState } from '../checkout.state';
import { SurgicalGuideCheckoutActions } from '../surgicalGuide-checkout.actions';
import type { ReducerMap } from 'redux-actions';

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const surgicalGuideCheckoutReducerMap: ReducerMap<CheckoutState, any> = {
    ...SurgicalGuideCheckoutActions.SET_CBCT_URL.reducer<CheckoutState>((state, action) => {
        return {
            ...state,
            surgicalGuideCheckout: {
                ...state.surgicalGuideCheckout,
                cbct_url: action.payload,
            },
        };
    }),
};
