import { SimpleMultiSelect } from '@orthly/ui';
import { Box, FormControl, TextField, Text } from '@orthly/ui-primitives';
import React from 'react';

interface CasePlanningCalendlyModalFormInputProps {
    label: string;
    name: string;
    value: string | string[];
    error: string | undefined;
    touched: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement | { value: unknown }>) => void;
    onBlur: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
    options?: { label: string; value: string }[]; // for select options
    inputType: 'select' | 'text';
    helperText?: string;
    placeholder?: string;
    multiline?: boolean;
}

export const CasePlanningCalendlyModalFormInput: React.VFC<CasePlanningCalendlyModalFormInputProps> = ({
    label,
    name,
    value,
    error,
    touched,
    onChange,
    onBlur,
    options,
    inputType,
    helperText,
    placeholder,
    multiline,
}) => {
    const showError = touched && !!error;

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ display: 'flex' }}>
                <Text variant={'body2'} medium color={'BLACK'} sx={{ marginBottom: '8px' }}>
                    {label}
                </Text>
                <Text variant={'body2'} medium color={'ATTENTION_FOREGROUND'}>
                    *
                </Text>
            </Box>
            <FormControl fullWidth error={showError}>
                {inputType === 'select' && options ? (
                    <SimpleMultiSelect
                        label={name}
                        options={options.map(option => {
                            return { label: option.label, value: option.value };
                        })}
                        // @ts-ignore
                        onChange={onChange}
                        value={Array.isArray(value) ? value : []}
                        onBlur={onBlur}
                    />
                ) : (
                    <TextField
                        fullWidth
                        multiline={!!multiline}
                        rows={multiline ? 2 : undefined}
                        name={name}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        error={showError}
                        helperText={showError ? error : helperText}
                        placeholder={placeholder}
                        InputProps={{
                            sx: { padding: 0 },
                        }}
                    />
                )}
            </FormControl>
        </Box>
    );
};
