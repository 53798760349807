import { CheckoutItemV2Manager } from '../CheckoutItemV2Manager';
import type { CheckoutState } from '../checkout.state';
import { ImplantCheckoutActions } from '../implant-checkout.actions';
import { prevCheckoutStepReducer, nextCheckoutStepReducer } from './checkoutV2.reducer';
import {
    nextImplantStepForScanbodiesScreen,
    prevImplantStepForScanbodiesScreen,
} from './implant-checkout-scanbodies.reducer';
import { CartItemV2Utils } from '@orthly/items';
import { Assert } from '@orthly/runtime-utils';
import * as Sentry from '@sentry/react';
import _ from 'lodash';
import type { ReducerMap } from 'redux-actions';

/**
 * Reducer for 'next' when on the scanbodies screen.
 * This function is called by both the next button and when a scanbody is added to automatically advance.
 */
function nextScreenScanbodies(state: CheckoutState): CheckoutState {
    const { implantCheckout } = state;

    const scanbodiesScreen = nextImplantStepForScanbodiesScreen(state);
    if (scanbodiesScreen === 'changeScreen') {
        return nextCheckoutStepReducer({
            ...state,
            implantCheckout: {
                ...implantCheckout,
                missingUploadWarningShowing: false,
                allowInstructions: false,
                scanbodiesScreen: {
                    ...implantCheckout.scanbodiesScreen,
                    motion: 'back', // so the transition is correct if the user returns
                },
            },
        });
    }

    return {
        ...state,
        implantCheckout: {
            ...implantCheckout,
            scanbodiesScreen,
        },
    };
}

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const implantCheckoutActions: ReducerMap<CheckoutState, any> = {
    ...ImplantCheckoutActions.PREV_IMPLANT_STEP.reducer<CheckoutState>((state, action) => {
        if (state.step !== 2) {
            return state;
        }

        const { implantCheckout } = state;
        const { screen } = implantCheckout;

        if (screen === 'instructions') {
            return prevCheckoutStepReducer({
                ...state,
                implantCheckout: {
                    ...implantCheckout,
                    missingUploadWarningShowing: false,
                    allowInstructions: false,
                },
            });
        }

        if (screen === 'upload') {
            if (action.payload.numUploadedPhotos > 0) {
                // don't go back to instructions screen if user already uploaded photos; they know what they're doing
                return prevCheckoutStepReducer({
                    ...state,
                    implantCheckout: {
                        ...implantCheckout,
                        screen: 'instructions',
                        missingUploadWarningShowing: false,
                        allowInstructions: false,
                    },
                });
            }

            return {
                ...state,
                implantCheckout: {
                    ...implantCheckout,
                    screen: 'instructions',
                    missingUploadWarningShowing: false,
                    allowInstructions: true,
                },
            };
        }

        if (screen === 'scanbodies') {
            const scanbodiesScreen = prevImplantStepForScanbodiesScreen(state);
            if (scanbodiesScreen === 'changeScreen') {
                return {
                    ...state,
                    implantCheckout: {
                        ...implantCheckout,
                        screen: 'upload',
                        missingUploadWarningShowing: false,
                        allowInstructions: false,
                    },
                };
            }

            return {
                ...state,
                implantCheckout: {
                    ...implantCheckout,
                    scanbodiesScreen,
                },
            };
        }

        Assert.unreachable(screen);
    }),

    ...ImplantCheckoutActions.TRY_NEXT_IMPLANT_STEP.reducer<CheckoutState>((state, action) => {
        if (state.step !== 2) {
            return state;
        }

        const { implantCheckout } = state;
        const { screen, missingUploadWarningShowing, scanbodiesScreen } = implantCheckout;

        const nothingUploaded = action.payload.numUploadedPhotos === 0;

        if (screen === 'instructions') {
            if (nothingUploaded) {
                // they're trying to skip uploading -- ignore first click and show warning
                if (!missingUploadWarningShowing) {
                    return {
                        ...state,
                        implantCheckout: {
                            ...implantCheckout,
                            missingUploadWarningShowing: true,
                            allowInstructions: false,
                        },
                    };
                }

                // already seen warning
                return {
                    ...state,
                    implantCheckout: {
                        ...implantCheckout,
                        screen: 'scanbodies',
                        allowInstructions: false,
                        missingUploadWarningShowing: false,
                        scanbodiesScreen: {
                            ...scanbodiesScreen,
                            motion: 'next',
                        },
                    },
                };
            }

            // they started an upload, take them to appropriate upload screen
            return {
                ...state,
                implantCheckout: {
                    ...implantCheckout,
                    screen: 'upload',
                    missingUploadWarningShowing: false,
                    allowInstructions: false,
                },
            };
        }

        if (screen === 'upload') {
            const canAdvance = action.payload.isUploadComplete || implantCheckout.missingUploadWarningShowing;

            if (canAdvance) {
                return {
                    ...state,
                    implantCheckout: {
                        ...implantCheckout,
                        screen: 'scanbodies',
                        allowInstructions: false,
                        missingUploadWarningShowing: false,
                        scanbodiesScreen: {
                            ...scanbodiesScreen,
                            motion: 'next',
                        },
                    },
                };
            }

            // show warning for partial upload
            return {
                ...state,
                implantCheckout: {
                    ...implantCheckout,
                    allowInstructions: false,
                    missingUploadWarningShowing: true,
                },
            };
        }

        if (screen === 'scanbodies') {
            return nextScreenScanbodies(state);
        }

        Assert.unreachable(screen);
    }),

    ...ImplantCheckoutActions.START_UPLOAD.reducer<CheckoutState>(state => {
        return {
            ...state,
            implantCheckout: {
                ...state.implantCheckout,
                screen: 'upload',
                missingUploadWarningShowing: false,
                allowInstructions: false,
            },
        };
    }),

    ...ImplantCheckoutActions.CHECK_INSTRUCTIONS.reducer<CheckoutState>(state => {
        return {
            ...state,
            implantCheckout: {
                ...state.implantCheckout,
                screen: 'instructions',
                allowInstructions: true,
                missingUploadWarningShowing: false,
            },
        };
    }),

    ...ImplantCheckoutActions.SET_MISSING_UPLOAD_WARNING.reducer<CheckoutState>((state, action) => {
        return {
            ...state,
            implantCheckout: {
                ...state.implantCheckout,
                missingUploadWarningShowing: action.payload,
            },
        };
    }),

    ...ImplantCheckoutActions.ADD_SCANBODY_FOR_ITEM_TOOTH.reducer<CheckoutState>((state, action) => {
        const item = state.items.find(i => i.item_index === action.payload.item_index);
        if (!item) {
            return state;
        }

        if (!CartItemV2Utils.itemTypeHasImplant(item)) {
            console.warn('tried to add scan body to non-implant item');
            Sentry.captureMessage('tried to add a scan body to a non-implant item', 'warning');
            return state;
        }

        const updatedItem = CheckoutItemV2Manager.applyUpdate(item, {
            name: 'scanbody_id',
            payload: _.pick(action.payload, ['unn', 'scanbody_id']),
        });

        if (!_.isEqual(item, updatedItem)) {
            // auto-advance if item was updated
            return nextScreenScanbodies({
                ...state,
                items: CheckoutItemV2Manager.sortItems([
                    ...state.items.filter(i => i.item_index !== action.payload.item_index),
                    updatedItem,
                ]),
            });
        }

        return state;
    }),
};
