import { useScreenIsMobile } from '@orthly/ui-primitives';
import { useLocation } from 'react-router-dom-v5-compat';

export const useGetSupport = () => {
    const isMobile = useScreenIsMobile();
    const location = useLocation();
    const isGetSupportIframe = location.pathname.includes('dandy_get_support_frame');
    const isFullScreen = isMobile || isGetSupportIframe;

    return {
        isFullScreen,
        isGetSupportIframe,
    };
};
