import { CalendlyModal } from '../Calendly';
import { useGetCalendlySessionPrefillData } from '../Waxups';
import { CasePlanningCalendlyModalContent } from './CasePlanningModalContent';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlLiveCasePlanningRequestEntryPoint } from '@orthly/graphql-schema';
import { OrthlyBrowserConfig } from '@orthly/ui';
import React from 'react';

interface CasePlanningCalendlyModalProps {
    casePlanningOpen: boolean;
    setCasePlanningOpen: React.Dispatch<React.SetStateAction<boolean>>;
    entryPoint: LabsGqlLiveCasePlanningRequestEntryPoint;
}

export const CasePlanningCalendlyModal: React.VFC<CasePlanningCalendlyModalProps> = ({
    casePlanningOpen,
    setCasePlanningOpen,
    entryPoint,
}) => {
    const [calendlyOpen, setCalendlyOpen] = React.useState(false);
    const [calendlyEventId, setCalendlyEventId] = React.useState<string | null>(null);
    const calendlyPrefill = useGetCalendlySessionPrefillData();
    const [isPatientTypeScreen, setIsPatientTypeScreen] = React.useState(true);
    const [isNewPatient, setIsNewPatient] = React.useState(false);
    const [hideCasePlanningModal, setHideCasePlanningModal] = React.useState(false);

    const resetState = () => {
        setCasePlanningOpen(false);
        setIsNewPatient(false);
        setIsPatientTypeScreen(true);
        setHideCasePlanningModal(false);
    };
    return (
        <>
            <CasePlanningCalendlyModalContent
                casePlanningOpen={casePlanningOpen}
                setCasePlanningOpen={setCasePlanningOpen}
                setCalendlyOpen={setCalendlyOpen}
                calendlyEventId={calendlyEventId}
                entryPoint={entryPoint}
                setCalendlyEventId={setCalendlyEventId}
                isPatientTypeScreen={isPatientTypeScreen}
                setIsPatientTypeScreen={setIsPatientTypeScreen}
                isNewPatient={isNewPatient}
                setIsNewPatient={setIsNewPatient}
                resetState={resetState}
                hideCasePlanningModal={hideCasePlanningModal}
                setHideCasePlanningModal={setHideCasePlanningModal}
            />
            <CalendlyModal
                url={
                    // TODO: update dev url when we get, we do not have
                    OrthlyBrowserConfig.env === 'production'
                        ? 'https://calendly.com/d/cm3k-bcf-z7h/case-planning'
                        : 'https://calendly.com/engineering-meetdandy/live-design-review'
                }
                onModalClose={() => {
                    resetState();
                    setCalendlyOpen(false);
                    BrowserAnalyticsClientFactory.Instance?.track('Global - Live Case Planning Closed Calendly Modal', {
                        entryPoint,
                    });
                }}
                open={calendlyOpen}
                prefill={calendlyPrefill}
                onEventScheduled={async event => {
                    setCasePlanningOpen(false);
                    const calendlyEventId = event.data.payload.event.uri.split('/').at(-1) ?? '';
                    calendlyEventId && setCalendlyEventId(calendlyEventId);
                }}
            />
        </>
    );
};
