import CasePlanningHeader from '../../../assets/images/CasePlanningHeader.png';
import { useGetSupport } from './useGetSupport';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlLiveCasePlanningRequestEntryPoint } from '@orthly/graphql-schema';
import { Box, Button, FlossPalette, Icon, Text, styled } from '@orthly/ui-primitives';
import React from 'react';

const Container = styled(Box)({
    backgroundColor: FlossPalette.TAN,
    padding: '24px 16px',
});

const Header = styled(Box)({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '25px',
});

const ContentBox = styled(Box)({
    borderRadius: '16px',
    backgroundColor: FlossPalette.WHITE,
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    padding: '24px',
    marginBottom: '16px',
});

const IconButtons = styled(Button)({
    margin: '8px 0',
});

const PhoneTextWrapper = styled(Box)({
    display: 'flex',
    gap: '4px',
});

const FreeEarlyAccessText = styled(Text)({
    backgroundColor: FlossPalette.SECONDARY_FOREGROUND,
    borderRadius: '8px',
    border: `1px solid ${FlossPalette.SECONDARY_FOREGROUND}`,
    height: '26px',
    padding: '4px 8px',
});

interface GetSupportWindowContentProps {
    setCasePlanningOpen: React.Dispatch<React.SetStateAction<boolean>>;
    openChat?: () => void;
    closeWindow?: () => void;
    entryPoint: LabsGqlLiveCasePlanningRequestEntryPoint;
}

export const GetSupportWindowContent: React.VFC<GetSupportWindowContentProps> = ({
    setCasePlanningOpen,
    openChat,
    closeWindow,
    entryPoint,
}) => {
    const { isFullScreen, isGetSupportIframe } = useGetSupport();

    return (
        <>
            <Container>
                <Header>
                    <Text variant={'h5'} color={'BLACK'}>
                        Questions? We’re here to help
                    </Text>
                    {!isFullScreen && <Icon sx={{ cursor: 'pointer' }} icon={'Close'} onClick={closeWindow} />}
                </Header>
                {!isGetSupportIframe && (
                    <ContentBox>
                        <Text variant={'body1'} color={'BLACK'} medium>
                            General or Clinical Support
                        </Text>
                        <Text variant={'body2'} color={'DARK_GRAY'}>
                            Reach out about clinical, account, IT, or billing questions.
                        </Text>
                        <IconButtons
                            variant={'secondary-gray'}
                            startIcon={'SidebarChatIcon'}
                            onClick={async () => {
                                if (openChat) {
                                    await openChat();
                                }
                                if (!isFullScreen && closeWindow) {
                                    closeWindow();
                                }
                                BrowserAnalyticsClientFactory.Instance?.track('Global - Get Support Opened Chat', {
                                    entryPoint: entryPoint,
                                });
                            }}
                        >
                            Start a live chat
                        </IconButtons>
                        <PhoneTextWrapper>
                            <Text variant={'body2'} color={'DARK_GRAY'}>
                                or call Dandy at
                            </Text>
                            <Text variant={'body2'} color={'BLACK'} medium>
                                (267)-310-3332
                            </Text>
                        </PhoneTextWrapper>
                    </ContentBox>
                )}
                <ContentBox>
                    <Header>
                        <img
                            src={CasePlanningHeader}
                            style={{ width: '130px' }}
                            alt={'Case planning header image of small restoratives'}
                        />
                        <FreeEarlyAccessText variant={'caption'} medium color={'WHITE'}>
                            Free early access
                        </FreeEarlyAccessText>
                    </Header>
                    <Text variant={'body1'} color={'BLACK'} medium>
                        Case Planning for Fixed Products
                    </Text>
                    <Text variant={'body2'} color={'DARK_GRAY'}>
                        Talk to a Lab Technician to plan out your next complex case with confidence.
                    </Text>
                    <IconButtons
                        variant={'secondary-gray'}
                        startIcon={'CalendarIcon'}
                        onClick={() => {
                            setCasePlanningOpen(true);
                            if (closeWindow) {
                                closeWindow();
                            }
                            BrowserAnalyticsClientFactory.Instance?.track('Global - Get Support Opened Scheduler', {
                                entryPoint: entryPoint,
                            });
                        }}
                    >
                        Consult with a lab technician
                    </IconButtons>
                </ContentBox>
            </Container>
        </>
    );
};
