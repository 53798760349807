import { usePracticeBannerData } from '../PracticeBannerProvider.graphql';
import { AnalyticsClient } from '../analytics/analyticsClient';
import { usePartnerLeftNavButtonLinks, usePartnerRightNavButtonLinks } from '../constants';
import { useGetLoginOptions } from '../graphql/useGetLoginOptions.graphql';
import { useCurrentProfile, useStaffMemberMatcher } from '../redux';
import { usePracticeLogout } from '../redux/auth';
import { usePracticeStaffControls } from '../screens/account/state/usePracticeStaffControls';
import { useChatWindow } from '../screens/chat/ChatWindow/useChatWindow';
import { useSelectStaffAction } from '../screens/select-staff/state/select-staff.actions';
import { useIsChairside } from '../utils/chairside.hooks';
import { StaffProfilePhoto } from './StaffProfilePhoto';
import { HORIZONTAL_NAV_HEIGHT, HorizontalNav } from '@orthly/dentin';
import { LabsGqlLiveCasePlanningRequestEntryPoint } from '@orthly/graphql-schema';
import { MiniWindowVariant } from '@orthly/ui';
import { FlossPalette, PersonIcon, useScreenIsMd } from '@orthly/ui-primitives';
import { DandyNotifications, DandySearch, useFeatureFlag } from '@orthly/veneer';
import React from 'react';
import { useHistory } from 'react-router-dom';

export const PracticeTopNav: React.VFC = () => {
    const history = useHistory();
    const profile = useCurrentProfile();
    const chairside = useIsChairside();
    const logoutProfile = useSelectStaffAction('LOGOUT_STAFF');
    const logOut = usePracticeLogout();
    const { openWindow, closeWindow, windowVariant } = useChatWindow('horizontal_nav');
    const doctorMatches = useStaffMemberMatcher();
    const { value: enablePMS } = useFeatureFlag('enablePMS');

    const screenIsMedium = useScreenIsMd();
    const { contentPaddingTop } = usePracticeBannerData();

    const notificationsComponent = React.useMemo(() => {
        return (
            <DandyNotifications
                doctorMatches={doctorMatches}
                navigationAction={path => history.push(path)}
                backdropPaddingTop={contentPaddingTop}
            />
        );
    }, [doctorMatches, history, contentPaddingTop]);

    const setCSTOpen = (open: boolean) => {
        open ? openWindow() : closeWindow();
    };
    const controls = usePracticeStaffControls();
    const { loginOptions } = useGetLoginOptions(controls?.employeePrefs?.staff_member_id ?? '');
    const cstOpen = windowVariant !== MiniWindowVariant.Closed;
    const partnerLeftNavButtonLinks = usePartnerLeftNavButtonLinks();
    const partnerRightNavButtonLinks = usePartnerRightNavButtonLinks();
    const { value: enableCasePlanning = false } = useFeatureFlag('enableCasePlanning');

    return (
        <HorizontalNav
            enablePMS={!!enablePMS}
            chairside={chairside}
            profile={profile}
            switchProfileAction={logoutProfile}
            logoutAction={logOut}
            logoutText={'Logout'}
            notificationsComponent={notificationsComponent}
            searchComponent={<DandySearch backdropPaddingTop={contentPaddingTop} />}
            cstProps={{
                unreadCount: 0,
                setCSTOpen: enableCasePlanning
                    ? () => {
                          AnalyticsClient.track('Global - Get Support Opened on Click', {
                              entryPoint: LabsGqlLiveCasePlanningRequestEntryPoint.PracticeGetSupport,
                          });
                          setCSTOpen(true);
                      }
                    : setCSTOpen,
                cstOpen,
                backdropPaddingTop: contentPaddingTop,
                title: enableCasePlanning ? 'Get Support' : 'Chat with us',
                icon: <PersonIcon />,
            }}
            leftNavButtonLinks={partnerLeftNavButtonLinks}
            rightNavButtonLinks={partnerRightNavButtonLinks}
            screenIsMedium={screenIsMedium}
            smallProfilePhoto={
                <StaffProfilePhoto staff={profile} style={{ width: 32, height: 32, borderRadius: 2, fontSize: 14 }} />
            }
            largeProfilePhoto={
                <StaffProfilePhoto
                    staff={profile}
                    style={{
                        width: 48,
                        height: 48,
                        border: `1px solid ${FlossPalette.DARK_TAN}`,
                        borderRadius: 4,
                    }}
                />
            }
            navbarHeight={HORIZONTAL_NAV_HEIGHT}
            backdropPaddingTop={contentPaddingTop}
            disableChatBackdrop={true}
            openExternalLinkAction={externalLink =>
                window.open(`https://dandy.dental/${externalLink}`, '_blank')?.focus()
            }
            loginOptions={loginOptions}
        />
    );
};
