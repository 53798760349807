import { AnalyticsClient } from '../../analytics/analyticsClient';
import { PendingVariantFeedback_Fragment } from './Queries.graphql';
import { GetAllPendingFeedback_Query, SubmitVariantFeedback_Mutation } from './Queries.graphql';
import { Header } from './VariantFeedbackHeader';
import { OrderItemVariantFeedbackForm } from './order_item_feedback/OrderItemVariantFeedbackForm';
import { PendingFeedbackList } from './pending_feedback_list/PendingFeedbackList';
import { useMutation, useQuery } from '@apollo/client';
import { PracticeFullScreenDialog, PracticeScreen } from '@orthly/dentin';
import type { PendingVariantFeedback_FragmentFragment } from '@orthly/graphql-inline-react';
import { getFragmentData } from '@orthly/graphql-inline-react';
import { styled, Text, useScreenIsMobileOrVerticalTablet } from '@orthly/ui-primitives';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Define the enum for disappointment levels
export enum DisappointmentLevel {
    EXTREMELY_DISAPPOINTED = 'extremely_disappointed',
    SOMEWHAT_DISAPPOINTED = 'somewhat_disappointed',
    A_LITTLE_DISAPPOINTED = 'a_little_disappointed',
    NOT_AT_ALL_DISAPPOINTED = 'not_at_all_disappointed',
}

interface PendingFeedbackContainerProps {
    isMobile: boolean;
    pendingFeedback: PendingVariantFeedback_FragmentFragment[];
    loading: boolean;
}
const PendingFeedbackContainerFrame = styled('div')({
    padding: '16px',
    overflow: 'scroll',
});
const PendingFeedbackContainer: React.FC<PendingFeedbackContainerProps> = ({ isMobile, pendingFeedback, loading }) => {
    if (loading) {
        return null;
    }

    const noFeedback = !pendingFeedback || pendingFeedback.length === 0;

    return (
        <PendingFeedbackContainerFrame>
            {noFeedback ? (
                <Text variant={'h6'}>No pending feedback</Text>
            ) : (
                <PendingFeedbackList isMobile={isMobile} pendingFeedback={pendingFeedback} />
            )}
        </PendingFeedbackContainerFrame>
    );
};

interface OrderItemVariantFeedbackContainerProps {
    isMobile: boolean;
    orderId: string;
    orderItemId: string;
    afterSubmit: () => void;
}
export interface SubmitVariantFeedbackArgs {
    wasDropIn: boolean;
    crownChoice: number;
    timeToSeat: number;
    starRating: number;
    freeformFeedback: string;
    wouldYouBeDisappointed: DisappointmentLevel;
}
const OrderItemVariantFeedbackContainerFrame = styled('div')({
    padding: '16px',
    overflow: 'scroll',
});

const OrderItemVariantFeedbackContainer: React.VFC<OrderItemVariantFeedbackContainerProps> = ({
    isMobile,
    orderId,
    orderItemId,
    afterSubmit,
}) => {
    const [submitMtn] = useMutation(SubmitVariantFeedback_Mutation);
    const submitFn = async (args: SubmitVariantFeedbackArgs) => {
        await submitMtn({
            variables: {
                data: {
                    lab_order_item_id: orderItemId,
                    crown_choice: args.crownChoice.toString(),
                    was_drop_in: args.wasDropIn,
                    time_to_seat: args.timeToSeat,
                    star_rating: args.starRating,
                    freeform_feedback: args.freeformFeedback,
                    would_you_be_disappointed: args.wouldYouBeDisappointed,
                },
            },
        }).then(() => {
            AnalyticsClient.track('Practice - Variant - Feedback Submitted', {
                orderItem: orderItemId,
                crownChoice: args.crownChoice.toString(),
                wasDropIn: args.wasDropIn,
                timeToSeat: args.timeToSeat,
                starRating: args.starRating,
                wouldYouBeDisappointed: args.wouldYouBeDisappointed,
                $groups: {
                    order: orderId,
                },
            });
            afterSubmit();
        });
    };

    return (
        <OrderItemVariantFeedbackContainerFrame>
            <OrderItemVariantFeedbackForm onSubmit={submitFn} isMobile={isMobile} />
        </OrderItemVariantFeedbackContainerFrame>
    );
};

export const VariantFeedbackRoot: React.VFC = () => {
    const history = useHistory();
    const isMobile = useScreenIsMobileOrVerticalTablet();

    const { orderId, orderItemId } = useParams<{ orderId?: string; orderItemId?: string }>();

    const { data, loading, refetch } = useQuery(GetAllPendingFeedback_Query, {
        variables: {
            data: {}, // Don't need any query params (e.g. date filters) right now.
        },
        fetchPolicy: 'no-cache',
    });

    const afterSubmitFeedback = async () => {
        history.push(`/${PracticeScreen.variant_feedback}`);
        await refetch();
    };

    const onCloseToolbar = async () => {
        if (orderId && orderItemId) {
            history.push(`/${PracticeScreen.variant_feedback}`);
            await refetch();
        } else {
            history.push(`/${PracticeScreen.inbox}`);
        }
    };

    let pendingFeedbackData =
        getFragmentData(PendingVariantFeedback_Fragment, data?.getAllPendingVariantFeedback) || [];
    let itemId = orderItemId;
    if (orderId && !itemId) {
        // If we only have an order ID, filter all pending feedback for that order.
        pendingFeedbackData = pendingFeedbackData.filter(feedback => feedback.lab_order_id === orderId);

        // If there is only one pending feedback, set the order item ID implicitly
        if (pendingFeedbackData[0] && pendingFeedbackData.length === 1) {
            itemId = pendingFeedbackData[0].lab_order_item_id;
        }
    }

    return (
        <PracticeFullScreenDialog dialogPaperStyle={{ overflow: 'hidden' }}>
            <Header
                isMobile={isMobile}
                orderId={orderId}
                orderItemId={itemId}
                feedback={pendingFeedbackData as PendingVariantFeedback_FragmentFragment[]}
                onClose={onCloseToolbar}
            />
            {!itemId && (
                <PendingFeedbackContainer
                    isMobile={isMobile}
                    loading={loading}
                    pendingFeedback={pendingFeedbackData as PendingVariantFeedback_FragmentFragment[]}
                />
            )}
            {orderId && itemId && (
                <OrderItemVariantFeedbackContainer
                    isMobile={isMobile}
                    orderId={orderId}
                    orderItemId={itemId}
                    afterSubmit={afterSubmitFeedback}
                />
            )}
        </PracticeFullScreenDialog>
    );
};
