import { useCurrentProfile } from '../../redux';
import { useMutation, useQuery } from '@apollo/client';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import { graphql } from '@orthly/graphql-inline-react';
import {
    LabsGqlLiveDdpReviewRequestEntryPoint,
    LabsGqlLiveDdpReviewRequestStatus,
    LabsGqlWorkflowTaskType,
} from '@orthly/graphql-schema';
import { ErrorPage, LoadBlockerLoader, useQueryParam, useRootActionCommand } from '@orthly/ui';
import { styled, Text } from '@orthly/ui-primitives';
import { CalendlyContainer, useLdrCalendlySchedulingUrl } from '@orthly/veneer';
import dayjs from 'dayjs';
import React from 'react';

const ErrorPageBody = styled('div')({
    width: '100%',
    padding: '16px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '16px',
});

const ScheduleLiveDDPCall_Mutation = graphql(`
    mutation VeneerScheduleLiveDDPCall_Mutation($args: CreateLiveDdpReviewRequestArgs!) {
        createLiveDdpReviewRequest(args: $args) {
            id
        }
    }
`);

const GetAllDesignOrderDesignRevisions_Query = graphql(`
    query GetAllDesignOrderDesignRevisions_Query($orderId: String!) {
        getAllDesignOrderDesignRevisions(labOrderId: $orderId) {
            id
            created_at
            is_latest_design
        }
    }
`);

const GetLabOrder_Query = graphql(`
    query GetLabOrder_Query($id: String!) {
        lab_order(id: $id) {
            id
            doctor_preferences_id
            fulfillment_workflow {
                active_task {
                    ... on IWorkflowTask {
                        type
                    }
                    ... on DesignReviewWorkflowTask {
                        type
                    }
                    ... on DrReviewHoldWorkflowTask {
                        type
                    }
                    ... on ExternalDesignWorkflowTask {
                        type
                    }
                    ... on InternalDesignWorkflowTask {
                        type
                    }
                }
            }
        }
    }
`);

const GetLiveDdpReviewRequest_Query = graphql(`
    query GetLiveDdpReviewRequest_Query($orderId: String!) {
        getLiveDdpReviewRequest(order_id: $orderId) {
            id
            status
            calendly_event {
                meeting_url
                meeting_notes
                start_time
            }
        }
    }
`);

export const LiveDesignReviewRoot: React.VFC = () => {
    const [isUnauthorized, setIsUnauthorized] = React.useState(false);
    const [isAlreadyScheduled, setIsAlreadyScheduled] = React.useState(false);
    const [isLinkExpired, setIsLinkExpired] = React.useState(false);

    const profile = useCurrentProfile();
    const [order_id] = useQueryParam('order_id');
    const { calendlyUrl, loading: loadingCalendlyUrl } = useLdrCalendlySchedulingUrl(order_id);

    const { data: labOrder, loading: loadingLabOrder } = useQuery(GetLabOrder_Query, {
        variables: {
            id: order_id ?? '',
        },
        skip: !order_id,
    });

    const { data: slimDesignsData, loading: loadingSlimDesignData } = useQuery(GetAllDesignOrderDesignRevisions_Query, {
        variables: {
            orderId: order_id ?? '',
        },
        skip: !order_id,
    });

    const { data: ldrData, loading: loadingLdrData } = useQuery(GetLiveDdpReviewRequest_Query, {
        variables: {
            orderId: order_id ?? '',
        },
        skip: !order_id,
    });

    React.useEffect(() => {
        const doctorPrefIdFromOrder = labOrder?.lab_order.doctor_preferences_id;
        if (labOrder && profile && doctorPrefIdFromOrder !== profile?.id) {
            setIsUnauthorized(true);
        }
    }, [labOrder, profile]);

    React.useEffect(() => {
        const activeTask = labOrder?.lab_order.fulfillment_workflow.active_task?.type;
        if (labOrder && activeTask !== LabsGqlWorkflowTaskType.WaxupReview) {
            setIsLinkExpired(true);
        }
    }, [labOrder]);

    React.useEffect(() => {
        const ldrRequestStatus = ldrData?.getLiveDdpReviewRequest?.status;
        if (ldrRequestStatus === LabsGqlLiveDdpReviewRequestStatus.Scheduled) {
            setIsAlreadyScheduled(true);
        }
    }, [ldrData]);

    const rawMtn = useMutation(ScheduleLiveDDPCall_Mutation);
    const { submit } = useRootActionCommand(rawMtn, {
        successMessage: 'Live Design Review successfully scheduled!',
    });

    const loading = loadingLabOrder || loadingSlimDesignData || loadingLdrData || loadingCalendlyUrl || !profile;
    const latestDesign = slimDesignsData?.getAllDesignOrderDesignRevisions.find(revision => revision.is_latest_design);

    if (isUnauthorized) {
        return (
            <ErrorPage
                message={'Unauthorized'}
                text={"You don't have permissions for this page."}
                iconName={'PanTool'}
            />
        );
    }

    if (isAlreadyScheduled) {
        return (
            <ErrorPage message={`Live Design Review is already scheduled for this order`} text={''}>
                <ErrorPageBody>
                    <Text variant={'body1'}>
                        Your review is set for{' '}
                        <strong>
                            {dayjs(ldrData?.getLiveDdpReviewRequest?.calendly_event?.start_time).format(
                                'dddd, MMM D[th] YYYY [at] h:mm A',
                            )}
                        </strong>
                    </Text>
                    <Text variant={'body2'} color={'GRAY'} medium>
                        Please check your email for the meeting invite
                    </Text>
                </ErrorPageBody>
            </ErrorPage>
        );
    }

    if (isLinkExpired) {
        return (
            <ErrorPage
                message={'This Link has Expired'}
                text={'Sorry, Live Design Reviews can only be scheduled for orders with active designs'}
            />
        );
    }

    if (loading) {
        return <LoadBlockerLoader blocking={loading} />;
    }

    if (!calendlyUrl) {
        return <ErrorPage message={'Error'} text={'Failed to load Scheduling URL'} />;
    }

    return (
        <CalendlyContainer
            url={calendlyUrl}
            prefill={{
                email: profile?.contact_email ?? '',
                name: profile.formatted_name,
            }}
            onEventScheduled={async event => {
                const eventId = event.data.payload.event.uri.split('/').at(-1) ?? '';
                await submit({
                    args: {
                        order_id: order_id ?? '',
                        design_order_revision_id: latestDesign?.id ?? '',
                        calendly_event_id: eventId,
                        practice_id: profile.partner_id,
                        entry_point: LabsGqlLiveDdpReviewRequestEntryPoint.PracticeLiveDesignReviewSchedulerRoute,
                    },
                });

                BrowserAnalyticsClientFactory.Instance?.track(
                    'Practice - Portal - Live Design Review - Scheduled Call',
                    {
                        $groups: {
                            order: order_id ?? '',
                        },
                        revisionId: latestDesign?.id,
                        eventId,
                        entryPoint: LabsGqlLiveDdpReviewRequestEntryPoint.PracticeLiveDesignReviewSchedulerRoute,
                    },
                );
            }}
        />
    );
};
