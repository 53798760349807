import { CasePlanningCalendlyModalFormInput } from './CasePlanningModalFormInput';
import { useCreateLiveCasePlanningRequest } from './graphql/useCreateLiveCasePlanningRequest.graphql';
import { useListPracticePatients } from './graphql/useListPracticePatients.graphql';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlLiveCasePlanningRequestEntryPoint } from '@orthly/graphql-schema';
import { Box, FormControl, TextField, Text, Autocomplete, styled } from '@orthly/ui-primitives';
import { Form, Formik } from 'formik';
import React from 'react';

const TextFieldStyled = styled(TextField)({
    '& .MuiInputBase-formControl:first-child': {
        padding: '8px 0px 8px 8px',
    },
});

interface CasePlanningCalendlyModalFormProps {
    setSelectTimeDisabled: React.Dispatch<React.SetStateAction<boolean>>;
    calendlyEventId: string | null;
    entryPoint: LabsGqlLiveCasePlanningRequestEntryPoint;
    isNewPatient: boolean;
    setCalendlyEventId: React.Dispatch<React.SetStateAction<string | null>>;
    setIsPatientTypeScreen: (value: boolean) => void;
    setFormData: React.Dispatch<
        React.SetStateAction<{ patientId: string | null; productLine: string; topicToDiscuss: string }>
    >;
}

const productOptions = ['Anterior case', 'Posterior case', 'Implant'];

interface FormValues {
    patientName: string;
    productLine: string[];
    discussion: string;
}

interface ErrorValues {
    patientName: string;
    productLine: string;
    discussion: string;
}

const validate = (values: FormValues, isNewPatient: boolean) => {
    const errors = {} as ErrorValues;

    if (!isNewPatient && !values.patientName) {
        errors.patientName = 'Patient name is required';
    }

    if (!values.productLine.length) {
        // Double type assertions are unsafe and should be avoided.
        // eslint-disable-next-line @orthly/no-unknown-or-any-cast
        errors.productLine = 'Select at least one product line' as unknown as string;
    }

    if (!values.discussion) {
        errors.discussion = 'Please enter what you’d like to discuss';
    }

    return errors;
};

export const CasePlanningCalendlyModalForm: React.VFC<CasePlanningCalendlyModalFormProps> = ({
    setSelectTimeDisabled,
    calendlyEventId,
    entryPoint,
    isNewPatient,
    setCalendlyEventId,
    setIsPatientTypeScreen,
    setFormData,
}) => {
    const [hasSubmitted, setHasSubmitted] = React.useState(false);
    const { patients } = useListPracticePatients();
    const onSuccess = () => {
        console.log('Success!');
    };
    const { submit: createLiveCasePlanningRequest, submitting: submittingCreateLiveCasePlanningRequest } =
        useCreateLiveCasePlanningRequest({ onSuccess });

    const patientOptions =
        patients?.map(patient => ({
            label: `${patient.first_name} ${patient.last_name}`,
            value: patient.remote_id,
        })) || [];

    return (
        <Formik
            initialValues={{
                patientName: '',
                productLine: [],
                discussion: '',
            }}
            validate={(values: FormValues) => validate(values, isNewPatient)}
            onSubmit={async (values, { setSubmitting }) => {
                if (!submittingCreateLiveCasePlanningRequest && calendlyEventId) {
                    setSubmitting(true);
                    const patientId = isNewPatient ? null : values.patientName;
                    const productLine = values.productLine.join(', ');
                    const topicToDiscuss = values.discussion;

                    BrowserAnalyticsClientFactory.Instance?.track('Global - Live Case Planning Submitted', {
                        entryPoint,
                        patientId,
                        productLine,
                        topicToDiscuss,
                    });

                    await createLiveCasePlanningRequest({
                        patientId,
                        patientName: isNewPatient
                            ? 'New Patient'
                            : patientOptions.find(p => p.value === values.patientName)?.label || '',
                        productLine,
                        topicToDiscuss,
                        calendlyEventId,
                        entryPoint,
                    });
                }
                setCalendlyEventId(null);
                setSubmitting(false);
                setHasSubmitted(true);
                setIsPatientTypeScreen(true);
            }}
        >
            {({ values, errors, touched, handleBlur, setFieldValue, handleSubmit, isSubmitting }) => {
                const isFormValid =
                    (isNewPatient || values.patientName) && values.productLine.length > 0 && values.discussion;

                setSelectTimeDisabled(!isFormValid);
                if (isFormValid && !isSubmitting && !hasSubmitted && calendlyEventId) {
                    handleSubmit();
                }
                const patientId = isNewPatient ? null : values.patientName;
                const productLine = values.productLine.join(', ');
                const topicToDiscuss = values.discussion;
                return (
                    <Form>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                            {/* Patient Name with Typeahead Search */}
                            {!isNewPatient && (
                                <Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <Text variant={'body2'} medium color={'BLACK'} sx={{ marginBottom: '8px' }}>
                                            Patient Name
                                        </Text>
                                        <Text variant={'body2'} medium color={'ATTENTION_FOREGROUND'}>
                                            *
                                        </Text>
                                    </Box>
                                    <FormControl fullWidth error={touched.patientName && !!errors.patientName}>
                                        <Autocomplete
                                            options={patientOptions}
                                            getOptionLabel={option => option.label}
                                            value={patientOptions.find(p => p.value === values.patientName) || null}
                                            onChange={(_, newValue) => {
                                                setFieldValue('patientName', newValue ? newValue.value : '');
                                                setFormData({
                                                    patientId,
                                                    productLine,
                                                    topicToDiscuss,
                                                });
                                            }}
                                            renderInput={params => (
                                                <TextFieldStyled
                                                    {...params}
                                                    name={'patientName'}
                                                    onBlur={handleBlur}
                                                    error={touched.patientName && !!errors.patientName}
                                                    helperText={touched.patientName && errors.patientName}
                                                    placeholder={'Search by patient name'}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Box>
                            )}

                            {/* Product Line (MultiSelect) */}
                            <Box>
                                <CasePlanningCalendlyModalFormInput
                                    label={'Product Line'}
                                    name={'Make a selection'}
                                    value={values.productLine}
                                    error={errors.productLine && errors.productLine[0]}
                                    touched={!!touched.productLine}
                                    onChange={e => {
                                        setFieldValue('productLine', e);
                                        setFormData({
                                            patientId,
                                            productLine,
                                            topicToDiscuss,
                                        });
                                    }}
                                    onBlur={handleBlur}
                                    inputType={'select'}
                                    options={productOptions.map(option => ({ label: option, value: option }))}
                                />
                                <Text variant={'body2'} color={'GRAY'}>
                                    Only available for fixed products.
                                </Text>
                            </Box>

                            {/* Discussion */}
                            <Box>
                                <CasePlanningCalendlyModalFormInput
                                    label={'What would you like to discuss?'}
                                    name={'discussion'}
                                    value={values.discussion}
                                    error={errors.discussion}
                                    touched={!!touched.discussion}
                                    onChange={e => {
                                        setFieldValue('discussion', e.target.value);
                                        setFormData({
                                            patientId,
                                            productLine,
                                            topicToDiscuss,
                                        });
                                    }}
                                    onBlur={handleBlur}
                                    inputType={'text'}
                                    placeholder={'Ex: I want guidance on prepping the patient'}
                                    multiline={true}
                                />
                                <Text variant={'body2'} color={'GRAY'} sx={{ marginBottom: '8px' }}>
                                    Providing this context helps our lab technician prepare for your call.
                                </Text>
                            </Box>
                        </Box>
                    </Form>
                );
            }}
        </Formik>
    );
};
