import { useGetLoginOptions } from '../graphql/useGetLoginOptions.graphql';
import { useSelectStaffAction } from '../screens/select-staff/state/select-staff.actions';
import { PracticeUtils } from './utils';
import { useSession, useStaffMemberLoginProps } from '@orthly/session-client';
import React from 'react';

export function useSwitchPractice() {
    const session = useSession();
    const { loginOptions, loading } = useGetLoginOptions(session?.id);
    const { onSelectToken } = useStaffMemberLoginProps();
    const logoutStaff = useSelectStaffAction('LOGOUT_STAFF');

    // Effect checks current practice against query param and switches if they don't match
    const practiceIdFromUrl = PracticeUtils.getParsedQueryProperty('practiceId', location.search);
    React.useEffect(() => {
        if (session && !loading && practiceIdFromUrl) {
            // First, check that the passed practiceId is a valid option for this user.
            const targetPractice = loginOptions.find(option => option.organization_id === practiceIdFromUrl);

            if (targetPractice) {
                // Next, check if the target practice is already the current practice. If so, just remove it from the URL.
                if (targetPractice.organization_id !== session.organization_id) {
                    // Need to switch practices

                    // Remove practiceId query param before the switch
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.delete('practiceId');
                    const cleanUrl = `${location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}${location.hash}`;
                    history.replaceState(null, '', cleanUrl);

                    // Log out, switch practice, and reload.
                    // NOTE: This has issues if the underlying route is a redirect.
                    logoutStaff();
                    onSelectToken(targetPractice.access_token, targetPractice.id);
                    window.location.reload();
                } else {
                    // Already in correct practice, just remove practiceId query param
                    const searchParams = new URLSearchParams(location.search);
                    searchParams.delete('practiceId');
                    const cleanUrl = `${location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}${location.hash}`;
                    history.replaceState(null, '', cleanUrl);
                }
            }
        }
    }, [session, loginOptions, loading, practiceIdFromUrl, onSelectToken, logoutStaff]);
}
