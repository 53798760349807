import { MobilePageLayout } from '../../../layouts/mobile/MobilePageLayout';
import { ChatWindowContent } from '../../../screens/chat/ChatWindow/ChatWindowContent';
import { useChatWindow } from '../../../screens/chat/ChatWindow/useChatWindow';
import { useChat } from '../../../screens/chat/useChat';
import { LabsGqlLiveCasePlanningRequestEntryPoint } from '@orthly/graphql-schema';
import { MiniWindowCore, stylesFactory, FlossPalette } from '@orthly/ui';
import { useFeatureFlag, CasePlanningCalendlyModal, GetSupportWindowContent } from '@orthly/veneer';
import React from 'react';

const useStyles = stylesFactory(() => ({
    window: {
        width: '100%',
        height: '100%',
        overflow: 'scroll',
        backgroundColor: FlossPalette.TAN,
    },
}));

export const MobileChatRoot: React.VFC = () => {
    const classes = useStyles();
    const { windowVariant, windowPendingAnimation, maximizeWindow, setWindowPendingAnimation } = useChatWindow();
    const [casePlanningOpen, setCasePlanningOpen] = React.useState(false);
    const { value: enableCasePlanning = false } = useFeatureFlag('enableCasePlanning');
    const { openChat } = useChat();
    const entryPoint = LabsGqlLiveCasePlanningRequestEntryPoint.PracticeGetSupportMobile;

    React.useEffect(maximizeWindow, [maximizeWindow]);

    return (
        <MobilePageLayout title={'CST'} hideHeader>
            <MiniWindowCore
                variant={windowVariant}
                setVariant={() => {}}
                pendingAnimation={windowPendingAnimation}
                setPendingAnimation={setWindowPendingAnimation}
                className={classes.window}
            >
                {enableCasePlanning ? (
                    <GetSupportWindowContent
                        setCasePlanningOpen={setCasePlanningOpen}
                        openChat={openChat}
                        entryPoint={entryPoint}
                    />
                ) : (
                    <ChatWindowContent />
                )}
            </MiniWindowCore>
            <CasePlanningCalendlyModal
                casePlanningOpen={casePlanningOpen}
                setCasePlanningOpen={setCasePlanningOpen}
                entryPoint={entryPoint}
            />
        </MobilePageLayout>
    );
};
