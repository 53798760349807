import { useGetSupport } from '../GetSupport/useGetSupport';
import { CasePlanningCalendlyModalForm } from './CasePlanningModalForm';
import { CasePlanningModalPatientType } from './CasePlanningModalPatientType';
import { BrowserAnalyticsClientFactory } from '@orthly/analytics/dist/browser';
import type { LabsGqlLiveCasePlanningRequestEntryPoint } from '@orthly/graphql-schema';
import { Icon } from '@orthly/ui';
import { Box, Button, Dialog, FlossPalette, InfoIcon, PauseIcon, Text } from '@orthly/ui-primitives';
import React from 'react';

interface CasePlanningCalendlyModalContentProps {
    casePlanningOpen: boolean;
    setCasePlanningOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setCalendlyOpen: React.Dispatch<React.SetStateAction<boolean>>;
    calendlyEventId: string | null;
    entryPoint: LabsGqlLiveCasePlanningRequestEntryPoint;
    setCalendlyEventId: React.Dispatch<React.SetStateAction<string | null>>;
    isPatientTypeScreen: boolean;
    setIsPatientTypeScreen: (value: boolean) => void;
    isNewPatient: boolean;
    setIsNewPatient: (value: boolean) => void;
    resetState: () => void;
    hideCasePlanningModal: boolean;
    setHideCasePlanningModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CasePlanningCalendlyModalContent: React.VFC<CasePlanningCalendlyModalContentProps> = ({
    casePlanningOpen,
    setCasePlanningOpen,
    setCalendlyOpen,
    calendlyEventId,
    entryPoint,
    setCalendlyEventId,
    isPatientTypeScreen,
    setIsPatientTypeScreen,
    isNewPatient,
    setIsNewPatient,
    resetState,
    hideCasePlanningModal,
    setHideCasePlanningModal,
}) => {
    const [selectTimeDisabled, setSelectTimeDisabled] = React.useState(true);
    const [formData, setFormData] = React.useState<{
        patientId: string | null;
        productLine: string;
        topicToDiscuss: string;
    }>({
        patientId: '',
        productLine: '',
        topicToDiscuss: '',
    });
    const { isFullScreen } = useGetSupport();
    const { patientId, productLine, topicToDiscuss } = formData;

    return (
        <>
            <Dialog
                open={casePlanningOpen}
                onClose={() => {
                    resetState();
                }}
                fullWidth
                fullScreen={isFullScreen}
                sx={{
                    display: hideCasePlanningModal ? 'none' : 'block',
                    '& .MuiDialog-container': {
                        '& .MuiPaper-root': {
                            width: '100%',
                            maxWidth: '780px',
                        },
                    },
                }}
            >
                <Box sx={{ padding: '24px' }}>
                    <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                        <Icon
                            sx={{ cursor: 'pointer' }}
                            icon={'CloseIcon'}
                            onClick={() => {
                                resetState();
                                BrowserAnalyticsClientFactory.Instance?.track(
                                    'Global - Live Case Planning Closed Modal via X',
                                    {
                                        entryPoint,
                                    },
                                );
                            }}
                        />
                    </Box>
                    <Text sx={{ paddingBottom: '16px' }} variant={'h5'} color={'BLACK'}>
                        Case Planning Video Consultation
                    </Text>
                    <Text variant={'body2'} color={'DARK_GRAY'} sx={{ marginBottom: '16px' }}>
                        Talk to a Lab Technician to plan out your next complex case with confidence.
                    </Text>
                    {isPatientTypeScreen ? (
                        <CasePlanningModalPatientType
                            setIsPatientTypeScreen={setIsPatientTypeScreen}
                            setIsNewPatient={setIsNewPatient}
                        />
                    ) : (
                        <CasePlanningCalendlyModalForm
                            setSelectTimeDisabled={setSelectTimeDisabled}
                            calendlyEventId={calendlyEventId}
                            entryPoint={entryPoint}
                            isNewPatient={isNewPatient}
                            setCalendlyEventId={setCalendlyEventId}
                            setIsPatientTypeScreen={setIsPatientTypeScreen}
                            setFormData={setFormData}
                        />
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: 'flex-end',
                            paddingTop: '24px',
                            gap: '16px',
                        }}
                    >
                        {isPatientTypeScreen ? (
                            <Button
                                variant={'secondary'}
                                onClick={() => {
                                    setCasePlanningOpen(false);
                                    BrowserAnalyticsClientFactory.Instance?.track(
                                        'Global - Live Case Planning Closed Modal via Cancel',
                                        {
                                            entryPoint,
                                        },
                                    );
                                }}
                            >
                                Cancel
                            </Button>
                        ) : (
                            <>
                                <Button
                                    variant={'secondary'}
                                    onClick={() => {
                                        setIsPatientTypeScreen(true);
                                    }}
                                >
                                    Back
                                </Button>
                                <Button
                                    disabled={selectTimeDisabled}
                                    variant={'primary'}
                                    onClick={() => {
                                        setCalendlyOpen(true);
                                        setHideCasePlanningModal(true);
                                        BrowserAnalyticsClientFactory.Instance?.track(
                                            'Global - Live Case Planning Opened Calendly Modal',
                                            {
                                                entryPoint,
                                                patientId,
                                                productLine,
                                                topicToDiscuss,
                                            },
                                        );
                                    }}
                                >
                                    Select a time
                                </Button>
                            </>
                        )}
                    </Box>
                </Box>
                {!isFullScreen && !isPatientTypeScreen && !isNewPatient && (
                    <Box
                        sx={{
                            display: 'flex',
                            backgroundColor: FlossPalette.SECONDARY_BACKGROUND,
                            padding: '0 8px',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <InfoIcon style={{ marginRight: `8px`, color: FlossPalette.SECONDARY_FOREGROUND }} />
                        <Text variant={'body2'} color={'SECONDARY_FOREGROUND'} medium>
                            Booking an appointment won’t pause existing orders; manage holds on the order page
                        </Text>
                        <Button
                            variant={'ghost'}
                            onClick={() => {
                                window.open(
                                    'https://help.meetdandy.com/hc/en-us/articles/31417186153229-How-to-Pause-and-Resume-Orders',
                                    '_blank',
                                );
                            }}
                        >
                            Pause order <PauseIcon />
                        </Button>
                    </Box>
                )}
            </Dialog>
        </>
    );
};
