import { styled, FlossPalette } from '@orthly/ui';
import { FormControlLabel, RadioPrimitive as Radio, Text } from '@orthly/ui-primitives';
import React from 'react';

export interface WhichCrownDidYouSeatSelectorProps {
    onItemSelected: (item: number) => void;
    selectedItem: number | undefined;
    isMobile: boolean;
}

const Layout = styled('div')({
    display: 'grid',
    gridTemplateColumns: '256px 256px 256px',
    gridAutoRows: '256px',
    gridGap: '10px',
});

const CrownChoiceBox = styled('div')({
    border: `2px solid ${FlossPalette.STROKE_LIGHT}`,
    borderRadius: '16px',
    height: '100%',
    width: '100%',
    '&:hover': {
        cursor: 'pointer',
    },
});

const CrownChoiceText = styled(Text)({
    textAlign: 'center',
    margin: '16px',
});

const MobileLayout = styled('div')({
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridAutoRows: '80px',
    gridGap: '10px',
});

const MobileCrownChoiceBox = styled(CrownChoiceBox)({
    display: 'grid',
    gridTemplateColumns: '1fr',
});

const MobileCrownChoiceText = styled(CrownChoiceText)({});

export const WhichCrownDidYouSeatSelector: React.FC<WhichCrownDidYouSeatSelectorProps> = ({
    onItemSelected,
    selectedItem,
    isMobile,
}) => {
    if (isMobile) {
        return (
            <MobileLayout>
                <MobileCrownChoiceBox
                    sx={{ backgroundColor: FlossPalette.SECONDARY_FOREGROUND }}
                    onClick={() => onItemSelected(1)}
                >
                    <FormControlLabel
                        control={<Radio checked={selectedItem === 1} />}
                        label={
                            <MobileCrownChoiceText variant={'body1'} bold color={'WHITE'}>
                                Lighter IP Contacts
                            </MobileCrownChoiceText>
                        }
                    />
                </MobileCrownChoiceBox>
                <MobileCrownChoiceBox sx={{ backgroundColor: FlossPalette.BLACK }} onClick={() => onItemSelected(2)}>
                    <FormControlLabel
                        control={<Radio checked={selectedItem === 2} />}
                        label={
                            <MobileCrownChoiceText variant={'body1'} bold color={'WHITE'}>
                                Base IP Contacts
                            </MobileCrownChoiceText>
                        }
                    />
                </MobileCrownChoiceBox>
                <MobileCrownChoiceBox
                    sx={{ backgroundColor: FlossPalette.STAR_GRASS }}
                    onClick={() => onItemSelected(3)}
                >
                    <FormControlLabel
                        control={<Radio checked={selectedItem === 3} />}
                        label={
                            <MobileCrownChoiceText variant={'body1'} bold color={'WHITE'}>
                                Heavier IP Contacts
                            </MobileCrownChoiceText>
                        }
                    />
                </MobileCrownChoiceBox>
            </MobileLayout>
        );
    }

    return (
        <Layout>
            <CrownChoiceBox
                sx={{ backgroundColor: FlossPalette.SECONDARY_FOREGROUND }}
                onClick={() => onItemSelected(1)}
            >
                <Radio checked={selectedItem === 1} />
                <CrownChoiceText variant={'h4'} color={'WHITE'}>
                    Lighter IP Contacts
                </CrownChoiceText>
            </CrownChoiceBox>
            <CrownChoiceBox sx={{ backgroundColor: FlossPalette.BLACK }} onClick={() => onItemSelected(2)}>
                <Radio checked={selectedItem === 2} />
                <CrownChoiceText variant={'h4'} color={'WHITE'}>
                    Base IP Contacts
                </CrownChoiceText>
            </CrownChoiceBox>
            <CrownChoiceBox sx={{ backgroundColor: FlossPalette.STAR_GRASS }} onClick={() => onItemSelected(3)}>
                <Radio checked={selectedItem === 3} />
                <CrownChoiceText variant={'h4'} color={'WHITE'}>
                    Heavier IP Contacts
                </CrownChoiceText>
            </CrownChoiceBox>
        </Layout>
    );
};
