import { UnwrappedProductImageWrapper } from '@orthly/dentin';
import type { PendingVariantFeedback_FragmentFragment } from '@orthly/graphql-inline-react';
import { parseProductUnitType } from '@orthly/items';
import { Button, FlossPalette, Icon, styled, Text } from '@orthly/ui-primitives';
import React from 'react';

const Toolbar = styled('div')({
    display: 'grid',
    gridTemplateColumns: 'auto max-content',
    gridTemplateRows: '64px',
    alignItems: 'center',
    borderBottom: `1px solid ${FlossPalette.DARK_TAN}`,
    position: 'sticky',
    top: 0,
    zIndex: 2,
});
const Left = styled('div')({
    justifySelf: 'start',
    marginLeft: 12,
    alignItems: 'center',
    display: 'grid',
    gridTemplateColumns: 'auto auto auto',
    gridGap: '16px',
    justifyContent: 'left',
});
const Right = styled('div')({
    justifySelf: 'end',
    marginRight: 12,
});
const ToolbarButton = styled(Button)({
    padding: 8,
    minWidth: 0,
});

interface HeaderProps {
    isMobile: boolean;
    orderId?: string;
    orderItemId?: string;
    feedback: PendingVariantFeedback_FragmentFragment[];
    onClose: () => void;
}
export const Header: React.FC<HeaderProps> = ({ isMobile, orderId, orderItemId, feedback, onClose }) => {
    let order;
    if (orderId && orderItemId) {
        order = feedback.find(f => f.lab_order_id === orderId && f.lab_order_item_id === orderItemId);
    }

    const leftasaurus = order ? (
        <>
            {isMobile ? null : (
                <UnwrappedProductImageWrapper
                    product={parseProductUnitType('Crown')}
                    size={32}
                    backgroundColor={'WHITE'}
                />
            )}
            <Text variant={'h6'}>{order.patient_name}</Text>
            <Text variant={'body1'}>#{order.order_no}</Text>
        </>
    ) : (
        <Text variant={'h6'}>Perfect Fit Feedback</Text>
    );

    return (
        <Toolbar>
            <Left>{leftasaurus}</Left>
            <Right>
                <ToolbarButton variant={'secondary'} onClick={onClose}>
                    <Icon icon={'CloseIcon'} />
                </ToolbarButton>
            </Right>
        </Toolbar>
    );
};
