import { useQuery } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';

export const ListPracticePatients_Query = graphql(`
    query ListPracticePatients($with_replaced: Boolean) {
        listPracticePatients(with_replaced: $with_replaced) {
            remote_id: id
            first_name
            last_name
            birthday
            gender
            replaced_by_patient_id
        }
    }
`);

export const useListPracticePatients = () => {
    const { data, loading, error, refetch } = useQuery(ListPracticePatients_Query, {
        variables: {
            with_replaced: false,
        },
    });

    return {
        patients: data?.listPracticePatients,
        loading,
        error: error?.message,
        refetch,
    };
};
