import { PATIENT_PORTAL_ROUTE } from './routes';
import { history, store } from './store';
import { ConfigService } from './utils';
import { PracticeGqlProvider } from './utils/PracticeGqlProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { PracticeScreen } from '@orthly/dentin';
import { RetainerSessionProvider } from '@orthly/session-client';
import { OrthlyEnvironment } from '@orthly/shared-types';
import type { OrthlyBrowserConfig } from '@orthly/ui';
import {
    OrthlyFrontendApps,
    OrthlyErrorBoundary,
    LoadBlocker,
    StyledSnackbarProvider,
    OrthlyConfigProvider,
    ReloadStalePages,
} from '@orthly/ui';
import { DandyThemeProvider, Grid } from '@orthly/ui-primitives';
import {
    ConnectedFirebaseProvider,
    DandyStatsigProvider,
    LaunchDarklyProvider,
    useLaunchDarklyUserContext,
} from '@orthly/veneer';
import { useSnackbar } from 'notistack';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { QueryParamProvider } from 'use-query-params';

const ImpersonateLoadingFallback: React.FC = () => {
    return (
        <LoadBlocker blocking={true} loader={'dandy'}>
            <Grid container style={{ height: '100vh', width: '100vw' }} />
        </LoadBlocker>
    );
};

const PracticeSession: React.FC = props => {
    const { enqueueSnackbar } = useSnackbar();
    return (
        <RetainerSessionProvider
            allowedOrgTypes={['practice', 'lab']}
            allowImpersonate
            showNotification={(msg, isError) => {
                enqueueSnackbar(msg, { variant: isError ? 'error' : undefined });
            }}
            TokenExchangeLoadingFallback={ImpersonateLoadingFallback}
        >
            <DandyStatsigProvider>
                <ConnectedFirebaseProvider
                    appName={OrthlyFrontendApps.practice}
                    envOverride={process.env.REACT_APP_LOCAL_PROD ? OrthlyEnvironment.production : undefined}
                >
                    <QueryParamProvider ReactRouterRoute={Route}>{props.children}</QueryParamProvider>
                </ConnectedFirebaseProvider>
            </DandyStatsigProvider>
        </RetainerSessionProvider>
    );
};

export const PracticeRootProvider: React.FC<{ config: OrthlyBrowserConfig }> = props => {
    const ldContext = useLaunchDarklyUserContext();

    return (
        <DandyThemeProvider>
            <Suspense fallback={<LoadBlocker blocking={true} loader={'dandy'} />}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <OrthlyErrorBoundary componentName={'PracticeAppRoot'}>
                        <OrthlyConfigProvider config={props.config}>
                            <StyledSnackbarProvider
                                maxSnack={1}
                                autoHideDuration={3500}
                                // Parker: this is lazy but i cant figure out what has an obscene z-index so gotta beat it
                                style={{ zIndex: 100000 }}
                            >
                                <Provider
                                    store={
                                        // EPDPLT-4736: Using any is unsafe and should be avoided.
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        store as any
                                    }
                                >
                                    <Router history={history}>
                                        <CompatRouter>
                                            <ReloadStalePages
                                                safeToReloadRoutes={[
                                                    PracticeScreen.login,
                                                    PATIENT_PORTAL_ROUTE,
                                                    PracticeScreen.inbox,
                                                    PracticeScreen.my_practice,
                                                    PracticeScreen.lab,
                                                    PracticeScreen.orders,
                                                ]}
                                                maxAgeInDays={3}
                                            >
                                                <PracticeGqlProvider>
                                                    <PracticeSession>
                                                        <LaunchDarklyProvider
                                                            config={ConfigService}
                                                            context={ldContext}
                                                        >
                                                            {props.children}
                                                        </LaunchDarklyProvider>
                                                    </PracticeSession>
                                                </PracticeGqlProvider>
                                            </ReloadStalePages>
                                        </CompatRouter>
                                    </Router>
                                </Provider>
                            </StyledSnackbarProvider>
                        </OrthlyConfigProvider>
                    </OrthlyErrorBoundary>
                </LocalizationProvider>
            </Suspense>
        </DandyThemeProvider>
    );
};
