import { AnalyticsClient } from '../analytics/analyticsClient';
import { PracticeUtils } from './utils';
import { useGetDoctorPreferencesByIdQuery, usePartnerSessionForScannerQuery } from '@orthly/graphql-react';
import { useSession, useStaffMemberLoginProps } from '@orthly/session-client';
import type { RequireChildrenProps } from '@orthly/ui';
import { LoadBlocker } from '@orthly/ui';
import { Grid, Button, Text } from '@orthly/ui-primitives';
import * as Sentry from '@sentry/react';
import React from 'react';
import { useHistory } from 'react-router-dom';

const ScannerToPracticeLoginFallback: React.VFC<{ error: Error }> = ({ error }) => (
    <Grid container direction={'column'} alignItems={'center'} style={{ width: '100vw', height: '100vh' }}>
        <Text variant={'h5'}>Uh oh, looks like we've run into an error</Text>
        <Text variant={'caption'}>
            {error.name}: {error.message}
        </Text>
        <Button onClick={() => window.location.reload()} variant={'secondary'}>
            Retry
        </Button>
    </Grid>
);

/**
 * This blocker exchanges a scanner token in URL for a full-featured retainer session.
 *
 */
export const ScannerTokenSessionLoader: React.FC<RequireChildrenProps> = ({ children }) => {
    const history = useHistory();
    const { loadSessionFromLegacyToken } = useStaffMemberLoginProps();
    const scannerTokenFromQueryParam = PracticeUtils.getParsedQueryProperty('scannerToken', history.location.search);
    const prefId = PracticeUtils.getParsedQueryProperty('prefId', history.location.search);
    const staffMemberIdFromUrl = PracticeUtils.getParsedQueryProperty('staffMemberId', history.location.search);
    const pref = useGetDoctorPreferencesByIdQuery({
        variables: { doctor_id: prefId ?? '' },
        skip: !prefId || !!staffMemberIdFromUrl,
    });
    const staffMemberId = staffMemberIdFromUrl ?? pref?.data?.preferences?.staff_member_id;

    if (scannerTokenFromQueryParam) {
        Sentry.captureException(new Error('Unexpected scanner token found in page URL'));
    }

    const scannerToken = sessionStorage.getItem('scannerToken') ?? scannerTokenFromQueryParam;
    const session = useSession();
    const { loading, error } = usePartnerSessionForScannerQuery({
        variables: { staff_member_id: staffMemberId && staffMemberId !== '' ? staffMemberId : null },
        fetchPolicy: 'no-cache',
        // if a session already exists, we don't need to load it again for the scanner token
        skip: !staffMemberId && (!scannerToken || !!session),
        onCompleted: data => {
            const impersonateToken = data?.partnerSessionForScanner.native_id_token;
            if (!impersonateToken) {
                return;
            }
            void loadSessionFromLegacyToken(impersonateToken).then(() => {
                sessionStorage.removeItem('scannerToken');
            });
            if (prefId) {
                AnalyticsClient.identify(prefId, {
                    firstName: data.partnerSessionForScanner.user.first_name,
                    lastName: data.partnerSessionForScanner.user.last_name,
                    email: data.partnerSessionForScanner.user.email || undefined,
                });
            }
        },
    });

    if (error) {
        return <ScannerToPracticeLoginFallback error={error} />;
    }
    if (loading) {
        return (
            <LoadBlocker blocking loader={'dandy'} ContainerProps={{ style: { width: '100vw', height: '100vh' } }} />
        );
    }
    return <>{children}</>;
};
