import { PracticeProfilePhoto } from '../../../components/PracticeProfilePhoto';
import { getPartOfDay } from '../../../utils/utils';
import { useSelectStaffAction } from '../../select-staff/state/select-staff.actions';
import type { LabsGqlUserLoginOptionFragment } from '@orthly/graphql-operations';
import { useSession, useStaffMemberLoginProps } from '@orthly/session-client';
import { styled, useScreenIsMd, Grid, Button, Text, useScreenIsMobile } from '@orthly/ui-primitives';
import React from 'react';
import { useNavigate } from 'react-router-dom-v5-compat';

const MOBILE_AUTH_CONTENT_PADDING = 56;
const MOBILE_AUTH_HALF_CONTENT_PADDING = MOBILE_AUTH_CONTENT_PADDING / 2;
const MOBILE_AUTH_MOBILE_CONTENT_PADDING = 16;

const StyledButton = styled(Button)({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    height: '100%',
});

const GridContainer = styled(Grid)({
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 136px)',
});

const PracticeInfoContainer = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    maxWidth: '100%',
});

const PracticeNameText = styled(Text)({
    marginTop: '8px',
    textAlign: 'left',
    whiteSpace: 'normal',
});

const LeftContentWrapper = styled(Grid)(({ theme }) => ({
    padding: `120px ${MOBILE_AUTH_HALF_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px`,
    [theme.breakpoints.down('lg')]: {
        padding: `${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_HALF_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px`,
    },
    [theme.breakpoints.down('md')]: {
        padding: `${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px ${MOBILE_AUTH_HALF_CONTENT_PADDING}px ${MOBILE_AUTH_CONTENT_PADDING}px`,
    },
    [theme.breakpoints.down('sm')]: {
        padding: `${MOBILE_AUTH_MOBILE_CONTENT_PADDING}px`,
        width: '100%',
    },
}));

const PracticeButton: React.VFC<{
    selected: boolean;
    practice: LabsGqlUserLoginOptionFragment;
    onClick: () => void;
}> = props => {
    const { practice, onClick, selected } = props;
    return (
        <StyledButton variant={'text'} onClick={onClick}>
            <Grid sx={{ display: 'block' }}>
                <PracticeProfilePhoto selected={selected} />
                <Grid container justifyContent={'space-between'}>
                    <PracticeInfoContainer item>
                        <PracticeNameText variant={'body1'}>{practice.organization_name}</PracticeNameText>
                        <Text variant={'body2'} sx={{ fontSize: '12px' }} color={'DARK_GRAY'}>
                            {practice.organization_type}
                        </Text>
                    </PracticeInfoContainer>
                </Grid>
            </Grid>
        </StyledButton>
    );
};

export const MobileAuthSelectOrganization = (): JSX.Element | null => {
    const { onSelectToken, loginOptions } = useStaffMemberLoginProps();
    const isMobileOrTablet = useScreenIsMd();
    const navigate = useNavigate();
    const session = useSession();
    const selectedName = session?.organization_name;
    const logoutStaff = useSelectStaffAction('LOGOUT_STAFF');
    const isMobile = useScreenIsMobile();

    const handleSelectLocation = (option: LabsGqlUserLoginOptionFragment) => {
        onSelectToken(option.access_token, option.id);
        /*
            push to location_selected route and let
            MobileAuthLoginRoot handle redirect (async)
        */
        logoutStaff();
        navigate('/inbox');
    };

    if (!loginOptions) {
        navigate('/inbox', { replace: true });
        return null;
    }

    return (
        <Grid item container={isMobile} lg={7} md={9} sm={12}>
            <LeftContentWrapper>
                <Text variant={isMobileOrTablet ? 'h5' : 'h4'} color={'GRAY'}>
                    Good {getPartOfDay()}!
                </Text>
                <Text variant={isMobileOrTablet ? 'h4' : 'h2'}>Select a practice to continue</Text>
                <GridContainer container sx={{ marginTop: '32px', gap: '32px' }}>
                    {loginOptions.map((option, idx) => (
                        <PracticeButton
                            selected={selectedName === option.organization_name}
                            key={idx}
                            practice={option}
                            onClick={() => handleSelectLocation(option)}
                        />
                    ))}
                </GridContainer>
            </LeftContentWrapper>
        </Grid>
    );
};
