import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import { useChangeSubmissionFn } from '@orthly/ui';
import React from 'react';

const addPhoneToDrPrefAndUser_Mutation = graphql(`
    mutation addPhoneToDrPrefAndUser($args: AddPhoneToDrPrefAndUserArgs!) {
        addPhoneToDrPrefAndUser(args: $args)
    }
`);

export function useVerifyLoginPhoneNumber({ onSuccess }: { onSuccess?: () => void }) {
    const [error, setError] = React.useState<string>();
    const [submitMtn] = useMutation(addPhoneToDrPrefAndUser_Mutation);
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { submit: verifyPhoneNumber, ...rest } = useChangeSubmissionFn<any, any>(
        variables => submitMtn({ variables }),
        {
            disableDefaultErrorMessage: true,
            onSuccess: data => {
                //TODO: confirm this works
                if (data) {
                    setError(undefined);
                    onSuccess?.();
                } else {
                    setError('Something went wrong.  Please try again.');
                }
            },
            onError: e => {
                setError(e?.message);
            },
        },
    );

    const submit = React.useCallback(
        async (preferences_id: string, mobile_phone_number: string, code: string, user_id: string) => {
            if (mobile_phone_number && code) {
                return verifyPhoneNumber({ args: { preferences_id, mobile_phone_number, code, user_id } });
            }
        },
        [verifyPhoneNumber],
    );

    return {
        error,
        submit,
        ...rest,
    };
}
