import { ImplantReadinessActions } from './ImplantReadiness.actions';
import type { ImplantReadinessState, ImplantReadinessUrlData } from './ImplantReadiness.types';
import { AbutmentType } from './ImplantReadiness.types';
import { PracticeScreen } from '@orthly/dentin';
import { CartItemV2UpdateUtils } from '@orthly/items';
import _ from 'lodash';
import { matchPath } from 'react-router-dom';
import type { Action } from 'redux-actions';
import { handleActions } from 'redux-actions';
import type { RouterState } from 'redux-first-history';
import { LOCATION_CHANGE } from 'redux-first-history';
import { v4 } from 'uuid';

const initialState: ImplantReadinessState = {
    implantReadinessId: '',
    patient: undefined,
    items: [],
    scanbodies: [],
    isGrouped: true,
    itemAbutmentTypes: {},
    itemScanbodyOptions: {},
    surgicalReportPath: '',
    activeItemId: '',
    activePath: `/${PracticeScreen.scanbodies}`,
};

/**
 * Only sets an abutment type for an item when a single match is found
 */
function calculateAbutmentTypeForItems(state: ImplantReadinessState) {
    return state.items.reduce<ImplantReadinessState['itemAbutmentTypes']>((abutmentTypes, item) => {
        if (!item.id) {
            return abutmentTypes;
        }
        const options = state.itemScanbodyOptions[item.id];
        const hasAuthentic = !!options?.authentic;
        const hasGeneric = !!options?.generic;

        return {
            ...abutmentTypes,
            ...(hasAuthentic && !hasGeneric && { [item.id]: AbutmentType.Authentic }),
            ...(hasGeneric && !hasAuthentic && { [item.id]: AbutmentType.Generic }),
        };
    }, {});
}

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const implantReadinessReducer = handleActions<ImplantReadinessState, any>(
    {
        [LOCATION_CHANGE]: (state, action: Action<RouterState>) => {
            if (!action.payload.location) {
                return state;
            }

            if (action.payload.location.pathname === `/${PracticeScreen.scanbodies}`) {
                return { ...initialState };
            }
            // On the success stage, navigating back redirects the user to the start of the flow
            const invalidEntry =
                !state.patient &&
                state.activePath !== `/${PracticeScreen.scanbodies}/patient` &&
                state.activePath !== `/${PracticeScreen.scanbodies}/success`;

            if (invalidEntry) {
                return { ...state, activePath: `/${PracticeScreen.scanbodies}/patient` };
            }

            const { id } = matchPath<ImplantReadinessUrlData>(action.payload.location.pathname, {
                path: `/${PracticeScreen.scanbodies}/:path/:id`,
                exact: true,
            })?.params ?? { path: '', id: '' };

            const activeItemId = id;
            return {
                ...state,
                activeItemId: activeItemId || (state.items[0]?.id ?? ''),
                activePath: action.payload.location.pathname,
            };
        },
        ...ImplantReadinessActions.SET_IMPLANT_READINESS_ID.reducer<ImplantReadinessState>((state, action) => ({
            ...state,
            implantReadinessId: action.payload,
        })),
        ...ImplantReadinessActions.SET_PATIENT.reducer<ImplantReadinessState>((state, action) => ({
            ...state,
            patient: action.payload,
            activePath: action.payload ? `/${PracticeScreen.scanbodies}/type` : state.activePath,
        })),
        ...ImplantReadinessActions.SET_IMPLANT_TYPE.reducer<ImplantReadinessState>((state, action) => ({
            ...state,
            activePath: `/${PracticeScreen.scanbodies}/${action.payload}`,
        })),
        ...ImplantReadinessActions.CREATE_ITEMS.reducer<ImplantReadinessState>((state, action) => {
            const items = action.payload.map(item => ({
                id: v4(),
                ...item,
            }));
            return {
                ...state,
                items,
                activeItemId: items[0]?.id || '',
                itemAbutmentTypes: {},
                scanbodies: state.scanbodies.filter(sb => items.some(i => i.id === sb.itemId)),
                activePath: `/${PracticeScreen.scanbodies}/system/${items[0]?.id}`,
            };
        }),
        ...ImplantReadinessActions.TOGGLE_GROUPING.reducer<ImplantReadinessState>((state, action) => ({
            ...state,
            isGrouped: action.payload,
        })),
        ...ImplantReadinessActions.UPDATE_METADATA.reducer<ImplantReadinessState>((state, action) => {
            return {
                ...state,
                items: state.items.map(item => {
                    if (state.isGrouped || item.id === state.activeItemId) {
                        return CartItemV2UpdateUtils.updateItem(item, {
                            name: 'implant_metadata',
                            payload: action.payload,
                        });
                    }
                    return item;
                }),
            };
        }),
        ...ImplantReadinessActions.COMPLETE_IMPLANT_SYSTEMS.reducer<ImplantReadinessState>((state, action) => {
            const nextItemIndex = state.items.findIndex(({ id }) => id === state.activeItemId) + 1;
            if (!state.isGrouped && nextItemIndex < state.items.length) {
                const activeItemId = state.items[nextItemIndex]?.id || '';
                return {
                    ...state,
                    itemScanbodyOptions: action.payload,
                    activeItemId,
                    activePath: `/${PracticeScreen.scanbodies}/system/${activeItemId}`,
                };
            }
            if (_.isEmpty(action.payload)) {
                return {
                    ...state,
                    activePath: `/${PracticeScreen.scanbodies}/nomatch`,
                };
            }

            const itemAbutmentTypes = calculateAbutmentTypeForItems({ ...state, itemScanbodyOptions: action.payload });
            // Set the active item to be 1st item without an abutment type already set
            const activeItemId = state.items.find(item => item.id && !itemAbutmentTypes[item.id])?.id ?? '';

            return {
                ...state,
                itemScanbodyOptions: action.payload,
                itemAbutmentTypes: { ...state.itemAbutmentTypes, ...itemAbutmentTypes },
                activeItemId,
                activePath: activeItemId
                    ? `/${PracticeScreen.scanbodies}/abutment/${activeItemId}`
                    : `/${PracticeScreen.scanbodies}/upload`,
            };
        }),
        ...ImplantReadinessActions.SET_ABUTMENT_TYPES.reducer<ImplantReadinessState>((state, action) => {
            let activeItemId = '';
            const nextItemIndex = state.items.findIndex(({ id }) => id === state.activeItemId) + 1;
            if (!state.isGrouped && nextItemIndex < state.items.length) {
                const itemAbutmentTypes = calculateAbutmentTypeForItems(state);
                activeItemId =
                    state.items.find((item, index) => index > nextItemIndex && item.id && !itemAbutmentTypes[item.id])
                        ?.id ?? '';
            }

            return {
                ...state,
                itemAbutmentTypes: { ...state.itemAbutmentTypes, ...action.payload.abutmentTypes },
                activeItemId,
                activePath: activeItemId
                    ? `/${PracticeScreen.scanbodies}/abutment/${activeItemId}`
                    : `/${PracticeScreen.scanbodies}/upload`,
            };
        }),
        ...ImplantReadinessActions.SET_SURGICAL_REPORT.reducer<ImplantReadinessState>((state, action) => ({
            ...state,
            surgicalReportPath: action.payload,
            activePath: `/${PracticeScreen.scanbodies}/scanbody`,
        })),
        ...ImplantReadinessActions.SET_SCANBODIES.reducer<ImplantReadinessState>((state, action) => ({
            ...state,
            scanbodies: action.payload,
            activePath: `/${PracticeScreen.scanbodies}/checkout`,
        })),
        // Once the flow is finished, we need to reset the state back to the initial values
        // so we don't carry any old data to the next implant readiness order
        ...ImplantReadinessActions.COMPLETE_FLOW.reducer<ImplantReadinessState>(() => {
            return { ...initialState, activePath: `/${PracticeScreen.scanbodies}/success` };
        }),
    },
    initialState,
);
