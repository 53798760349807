import { PracticeScreen } from '@orthly/dentin';
import type { PendingVariantFeedback_FragmentFragment } from '@orthly/graphql-inline-react';
import { CartItemV2Utils, OrderItemV2Utils } from '@orthly/items';
import type { GqlOrderItem } from '@orthly/items/dist/utils/GqlItemV2.types';
import { Button, FlossPalette, Icon, styled, Text } from '@orthly/ui-primitives';
import dayjs from 'dayjs';
import React from 'react';
import { useHistory } from 'react-router-dom';

const PendingFeedbackListRowFrame = styled('div')({
    display: 'grid',
    height: '80px',
    borderBottom: `2px solid ${FlossPalette.STROKE_LIGHT}`,
    alignItems: 'center',
    gridGap: '16px',
    gridTemplateColumns: 'auto max-content max-content',
});
const PendingFeedbackListDoubleStackItem = styled('div')({
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gridGap: '8px',
});
const PendingFeedbackListRowButton = styled(Button)({
    background: FlossPalette.TAN,
    border: `1px solid ${FlossPalette.DARK_TAN}`,
    borderRadius: '8px',
    padding: '12px 16px',
    textAlign: 'center',
    '&:hover': {
        background: FlossPalette.WHITE,
        cursor: 'pointer',
    },
    justifySelf: 'end',
});

const MobilePendingFeedbackListRowFrame = styled('div')({
    display: 'grid',
    height: '80px',
    borderBottom: `2px solid ${FlossPalette.STROKE_LIGHT}`,
    alignItems: 'center',
    gridGap: '16px',
    gridTemplateColumns: 'auto max-content',
});

interface PendingFeedbackListRowProps {
    isMobile: boolean;
    pendingFeedback: PendingVariantFeedback_FragmentFragment;
}
const PendingFeedbackListRow: React.FC<PendingFeedbackListRowProps> = ({ isMobile, pendingFeedback }) => {
    const history = useHistory();

    const items = OrderItemV2Utils.parseItems(pendingFeedback.items_v2 as GqlOrderItem[]);
    const item = items.find(item => item.id === pendingFeedback.lab_order_item_id);

    if (!item) {
        return null;
    }

    const itemName = CartItemV2Utils.getItemDisplayInfo(item).full_display_name;

    if (isMobile) {
        return (
            <MobilePendingFeedbackListRowFrame>
                <PendingFeedbackListDoubleStackItem sx={{ justifySelf: 'start' }}>
                    <Text variant={'body1'}>{`${pendingFeedback.patient_name} - ${itemName}`}</Text>
                    <Text variant={'body2'}>{`Dr. ${pendingFeedback.doctor_name}`}</Text>
                </PendingFeedbackListDoubleStackItem>
                <PendingFeedbackListRowButton
                    variant={'secondary'}
                    onClick={() => {
                        history.push(
                            `/${PracticeScreen.variant_feedback}/${pendingFeedback.lab_order_id}/${pendingFeedback.lab_order_item_id}`,
                        );
                    }}
                >
                    <Icon icon={'ChevronRight'} />
                </PendingFeedbackListRowButton>
            </MobilePendingFeedbackListRowFrame>
        );
    }

    return (
        <PendingFeedbackListRowFrame>
            <PendingFeedbackListDoubleStackItem sx={{ justifySelf: 'start' }}>
                <Text variant={'body1'}>{`${pendingFeedback.patient_name} - ${itemName}`}</Text>
                <Text variant={'body2'}>{`Dr. ${pendingFeedback.doctor_name}`}</Text>
            </PendingFeedbackListDoubleStackItem>
            <PendingFeedbackListDoubleStackItem sx={{ justifySelf: 'end' }}>
                {pendingFeedback.order_delivery_date ? (
                    <>
                        <Text variant={'body2'}>Delivered</Text>
                        <Text variant={'body1'}>{dayjs(pendingFeedback.order_delivery_date).format('MMM DD')}</Text>
                    </>
                ) : (
                    <>
                        <Text variant={'body2'}>Order Placed</Text>
                        <Text variant={'body1'}>{dayjs(pendingFeedback.order_created_date).format('MMM DD')}</Text>
                    </>
                )}
            </PendingFeedbackListDoubleStackItem>
            <PendingFeedbackListRowButton
                variant={'secondary'}
                onClick={() => {
                    history.push(
                        `/${PracticeScreen.variant_feedback}/${pendingFeedback.lab_order_id}/${pendingFeedback.lab_order_item_id}`,
                    );
                }}
            >
                <Text medium color={'GREEN'}>
                    Provide Feedback
                </Text>
            </PendingFeedbackListRowButton>
        </PendingFeedbackListRowFrame>
    );
};

const PendingFeedbackListTable = styled('div')({
    display: 'grid',
    gridGap: '16px',
});
interface PendingFeedbackListProps {
    isMobile: boolean;
    pendingFeedback: PendingVariantFeedback_FragmentFragment[];
}
export const PendingFeedbackList: React.FC<PendingFeedbackListProps> = ({ isMobile, pendingFeedback }) => {
    return (
        <PendingFeedbackListTable>
            <Text variant={'h6'}>Which order would you like to provide feedback on?</Text>
            {pendingFeedback.map(pf => (
                <PendingFeedbackListRow key={pf.id} isMobile={isMobile} pendingFeedback={pf} />
            ))}
        </PendingFeedbackListTable>
    );
};
