import { notificationsNeedRefetchAtom } from '../../Notifications';
import { useMutation } from '@apollo/client';
import { graphql } from '@orthly/graphql-inline-react';
import type { LabsGqlLiveCasePlanningRequestEntryPoint } from '@orthly/graphql-schema';
import { useChangeSubmissionFn } from '@orthly/ui';
import { useAtom } from 'jotai';

export const CreateLiveCasePlanningRequest_Mutation = graphql(`
    mutation CreateLiveCasePlanningRequest($args: CreateLiveCasePlanningRequestArgs!) {
        createLiveCasePlanningRequest(args: $args) {
            id
        }
    }
`);
export const useCreateLiveCasePlanningRequest = ({
    onSuccess,
    onError,
}: {
    onSuccess?: () => void;
    onError?: (message?: string) => void;
}) => {
    const [_, setNotificationsRefetch] = useAtom(notificationsNeedRefetchAtom);
    const [submitMtn, { error }] = useMutation(CreateLiveCasePlanningRequest_Mutation);
    const { submit: createLiveCasePlanningRequest, ...rest } = useChangeSubmissionFn(submitMtn, {
        disableDefaultErrorMessage: true,
        onSuccess: () => {
            setNotificationsRefetch(true);
            onSuccess?.();
        },
        onError: e => {
            onError?.(e.message);
        },
    });

    const submit = ({
        patientId,
        patientName,
        productLine,
        topicToDiscuss,
        calendlyEventId,
        entryPoint,
    }: {
        patientId: string | null;
        patientName: string;
        productLine: string;
        topicToDiscuss: string;
        calendlyEventId: string;
        entryPoint: LabsGqlLiveCasePlanningRequestEntryPoint;
    }) =>
        createLiveCasePlanningRequest({
            variables: {
                args: {
                    patient_id: patientId,
                    patient_name: patientName,
                    product_line: productLine,
                    topic_to_discuss: topicToDiscuss,
                    calendly_event_id: calendlyEventId,
                    entry_point: entryPoint,
                },
            },
        });

    return {
        error: error?.message,
        submit,
        ...rest,
    };
};
