import { LoginRoutes } from '../../../logged-out/LoginPaths';
import { MobileAuthSelectOrganization } from '../../../mobile-auth/screens/MobileAuthSelectOrganization';
import { Login } from './Login';
import { useStaffMemberLoginProps } from '@orthly/session-client';
import type { LocationDescriptorObject } from 'history';
import React from 'react';
import { Switch } from 'react-router-dom';
import { CompatRoute } from 'react-router-dom-v5-compat';

export const LoginWrapper: React.FC<{
    originalLocation: LocationDescriptorObject;
}> = ({ originalLocation }) => {
    const { loginOptions } = useStaffMemberLoginProps();

    return (
        <Switch>
            {loginOptions && (
                <CompatRoute path={LoginRoutes.select_location}>
                    <MobileAuthSelectOrganization />
                </CompatRoute>
            )}
            <CompatRoute exact path={'*'}>
                <Login originalLocation={originalLocation} />
            </CompatRoute>
        </Switch>
    );
};
