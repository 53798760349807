import { SCANNER_SUBMIT_ROUTE } from '../CheckoutPaths';
import { AlignerCheckoutActions } from '../aligners-checkout.actions';
import { checkoutCurrentAlignerStepCompletionState, selectAlignerCaseStage } from '../aligners-checkout.selectors';
import { AlignerCheckoutStep } from '../aligners-checkout.types';
import { CheckoutActions } from '../checkout.actions';
import { NEXT_ALIGNER_STEP_BY_STAGE_STEP, PREVIOUS_ALIGNER_STEP_BY_STAGE_STEP } from './aligners-checkout-steps';
import type { AlignerCheckoutState } from './aligners-checkout.types';
import { LabsGqlBiteConcernOptions, LabsGqlCrowdingResolutionOption } from '@orthly/graphql-schema';
import { matchPath } from 'react-router-dom';
import type { Action } from 'redux-actions';
import { handleActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'redux-first-history';
import type { RouterState } from 'redux-first-history';

const initialState: AlignerCheckoutState = {
    step: AlignerCheckoutStep.PatientInformation,
    deviceUpload: false,
    uploadedImageGCSPaths: [],
    movementRestrictedTeeth: null,
    attachmentRestrictedTeeth: null,
    extractionTeeth: null,
    interproximalSpaceSizes: null,
    crowdingResolution: {
        expansion: LabsGqlCrowdingResolutionOption.OnlyIfNeeded,
        proclination: LabsGqlCrowdingResolutionOption.OnlyIfNeeded,
        ipr: LabsGqlCrowdingResolutionOption.OnlyIfNeeded,
    },
    biteConcerns: {
        improve_overjet: LabsGqlBiteConcernOptions.ShowOutcome,
        improve_overbite: LabsGqlBiteConcernOptions.ShowOutcome,
        improve_midline: LabsGqlBiteConcernOptions.ShowOutcome,
    },
};

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const alignerCheckoutReducer = handleActions<AlignerCheckoutState, any>(
    {
        // reset state when the user navigates away from checkout
        [LOCATION_CHANGE]: (state: AlignerCheckoutState, action: Action<RouterState>) =>
            !action.payload.location ||
            matchPath(action.payload.location.pathname, `/lab/submit`) ||
            matchPath(action.payload.location.pathname, SCANNER_SUBMIT_ROUTE)
                ? state
                : initialState,
        ...CheckoutActions.SET_SCAN.reducer<AlignerCheckoutState>(() => initialState),
        ...AlignerCheckoutActions.SET_ALIGNER_STEP.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            step: action.payload,
        })),
        ...AlignerCheckoutActions.NEXT_ALIGNER_STEP.reducer<AlignerCheckoutState>(state => {
            const stage = selectAlignerCaseStage(state);
            const { step } = state;
            if (!checkoutCurrentAlignerStepCompletionState(state).complete) {
                return state;
            }
            return { ...state, step: NEXT_ALIGNER_STEP_BY_STAGE_STEP[stage][step] ?? step };
        }),
        ...AlignerCheckoutActions.PREV_ALIGNER_STEP.reducer<AlignerCheckoutState>(state => {
            const stage = selectAlignerCaseStage(state);
            const { step } = state;
            return { ...state, step: PREVIOUS_ALIGNER_STEP_BY_STAGE_STEP[stage][step] ?? step };
        }),
        ...AlignerCheckoutActions.GO_TO_SUMMARY.reducer<AlignerCheckoutState>((state, action) => {
            const pendingCase = action.payload;
            return {
                ...state,
                alignerImages: pendingCase.photos,
                estimate: pendingCase.estimate ?? undefined,
                rejection: pendingCase.rejection ?? undefined,
                alignerNotes: pendingCase.aligner_notes ?? undefined,
                // eslint-disable-next-line no-nested-ternary
                step: pendingCase.rejection
                    ? AlignerCheckoutStep.Rejection
                    : pendingCase.estimate
                      ? AlignerCheckoutStep.Summary
                      : AlignerCheckoutStep.Estimating,
            };
        }),
        ...AlignerCheckoutActions.SET_DESKTOP_UPLOAD.reducer<AlignerCheckoutState>((state, action) => {
            return {
                ...state,
                deviceUpload: action.payload,
                step: AlignerCheckoutStep.ImageUploadReview,
            };
        }),
        ...AlignerCheckoutActions.SET_UPLOADED_GCS_PATHS.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            uploadedImageGCSPaths: action.payload,
        })),
        ...AlignerCheckoutActions.SET_ALIGNER_PHOTOS.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            alignerImages: action.payload,
        })),
        ...AlignerCheckoutActions.SET_ALIGNER_ARCH.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            alignerArch: action.payload,
            movementRestrictedTeeth: initialState.movementRestrictedTeeth,
            attachmentRestrictedTeeth: initialState.attachmentRestrictedTeeth,
            extractionTeeth: initialState.extractionTeeth,
            interproximalSpaceSizes: initialState.interproximalSpaceSizes,
        })),
        ...AlignerCheckoutActions.SET_ALIGNER_TREATMENT_AREA.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            alignerTreatmentArea: action.payload,
        })),
        ...AlignerCheckoutActions.SET_MOVEMENT_RESTRICTED_TEETH.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            movementRestrictedTeeth: action.payload,
        })),
        ...AlignerCheckoutActions.SET_ATTACHMENT_RESTRICTED_TEETH.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            attachmentRestrictedTeeth: action.payload,
        })),
        ...AlignerCheckoutActions.SET_EXTRACTION_TEETH.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            extractionTeeth: action.payload,
        })),
        ...AlignerCheckoutActions.SET_INTERPROXIMAL_SPACE_SIZES.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            interproximalSpaceSizes: action.payload,
        })),
        ...AlignerCheckoutActions.SET_CROWDING_RESOLUTION.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            crowdingResolution: action.payload,
        })),
        ...AlignerCheckoutActions.SET_BITE_CONCERNS.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            biteConcerns: action.payload,
        })),
        ...AlignerCheckoutActions.SET_ALIGNER_NOTES.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            alignerNotes: action.payload,
        })),
        ...AlignerCheckoutActions.SET_ALIGNER_ESTIMATE_STEP.reducer<AlignerCheckoutState>((state, action) => {
            return { ...state, estimateStep: action.payload };
        }),
        ...AlignerCheckoutActions.SET_ALIGNER_ESTIMATE.reducer<AlignerCheckoutState>((state, action) => {
            return { ...state, estimate: action.payload };
        }),
        ...AlignerCheckoutActions.SET_ALIGNER_REJECTION.reducer<AlignerCheckoutState>((state, action) => {
            return {
                ...state,
                step: AlignerCheckoutStep.Rejection,
                rejection: action.payload,
            };
        }),
        ...AlignerCheckoutActions.SET_PATIENT_STEPS.reducer<AlignerCheckoutState>((state, action) => {
            return { ...state, overriddenPatientSteps: action.payload };
        }),
        ...AlignerCheckoutActions.SET_EXISTING_ORDER.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            existingOrder: action.payload,
        })),
        ...AlignerCheckoutActions.SET_RETAINER.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            retainer: action.payload,
        })),
        ...AlignerCheckoutActions.SET_RETAINER_PRICING.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            retainerPricing: action.payload,
        })),
        ...AlignerCheckoutActions.SET_REFINEMENT.reducer<AlignerCheckoutState>((state, action) => ({
            ...state,
            isRefinement: action.payload,
        })),
    },
    initialState,
);
