import { Text, Button, ChevronRight, styled } from '@orthly/ui-primitives';
import React from 'react';

const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
});

const ButtonContainer = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const StyledText = styled(Text)({
    marginBottom: '16px',
});

const PrimaryButton = styled(Button)(({ theme }) => ({
    width: '48%',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
}));

const FirstButton = styled(PrimaryButton)({
    marginBottom: '16px',
});

export const CasePlanningModalPatientType: React.VFC<{
    setIsPatientTypeScreen: (value: boolean) => void;
    setIsNewPatient: (value: boolean) => void;
}> = ({ setIsPatientTypeScreen, setIsNewPatient }) => {
    return (
        <Container>
            <StyledText variant={'body2'} medium>
                Select the type of case:
            </StyledText>
            <ButtonContainer>
                <FirstButton
                    onClick={() => {
                        setIsNewPatient(false);
                        setIsPatientTypeScreen(false);
                    }}
                    variant={'secondary-gray'}
                >
                    For an existing patient <ChevronRight />
                </FirstButton>
                <PrimaryButton
                    onClick={() => {
                        setIsNewPatient(true);
                        setIsPatientTypeScreen(false);
                    }}
                    variant={'secondary-gray'}
                >
                    For a new patient not on file <ChevronRight />
                </PrimaryButton>
            </ButtonContainer>
        </Container>
    );
};
