import { App } from './App';
import { PracticeRootProvider } from './PracticeRootProvider';
import './analytics/analyticsClient';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { ConfigService } from './utils';
import { setupOrthlyBrowser } from '@orthly/ui';
import '@orthly/ui/assets/fonts/BagossStandard.css';
import '@orthly/ui/assets/fonts/Inter.css';
import 'core-js/features/string/replace-all';
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';

// EPDPLT-4736: Using any is unsafe and should be avoided.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
(window as any).PACKAGE_VERSION = ConfigService.logging.RELEASE;
setupOrthlyBrowser(ConfigService);

// Strict mode only runs in development, see docs here: https://reactjs.org/docs/strict-mode.html
ReactDOM.render(
    <React.StrictMode>
        <PracticeRootProvider config={ConfigService}>
            <App />
        </PracticeRootProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
// eslint-disable-next-line sonarjs/no-collapsible-if
if (!process.env.BUNDLED_WITHOUT_WEBPACK) {
    // EPDPLT-4736: Using any is unsafe and should be avoided.
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((module as any).hot) {
        // EPDPLT-4736: Using any is unsafe and should be avoided.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (module as any).hot.accept();
    }
}

if (process.env.SERVE_ESBUILD) {
    new EventSource('/esbuild').addEventListener('change', () => location.reload());
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
